import React, {useState, useEffect, useRef} from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import './style.css';

import api from '../../services/api';

import { useHistory } from "react-router-dom";
import { DataGrid, ptBR, GridOverlay } from '@mui/x-data-grid';
import Swal from 'sweetalert2';

import Tab from "@mui/material/Tab";
import TabContext from "@material-ui/lab/TabContext";
import Tabs from "@mui/material/Tabs";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";

import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import ButtonDefault from '../../componentes/ButtonDefault';
import ButtonSecondary from '../../componentes/ButtonSecondary';


import Footer from '../../componentes/Footer';
import Submenu from '../../componentes/SubMenu';
import ModalMercadoPago from './modals/modalMercadoPago';

import { formataDinheiro } from '../../vendor/formatar';

export default function Faturas() {

  let IconInfo = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="13.718" height="13.718" viewBox="0 0 13.718 13.718">
      <path id="info" d="M6.859.5a6.859,6.859,0,1,0,6.859,6.859A6.862,6.862,0,0,0,6.859.5ZM8.118,7.72A1.259,1.259,0,0,0,6.859,6.46h0A1.259,1.259,0,0,0,5.6,7.72V10.4A1.259,1.259,0,0,0,6.859,11.66h0A1.259,1.259,0,0,0,8.118,10.4ZM6.859,3.058A1.259,1.259,0,1,0,8.118,4.318,1.26,1.26,0,0,0,6.859,3.058Z" transform="translate(0 -0.5)" fill="#0554c2" fillRule="evenodd"/>
    </svg>
  );

  let IconInfoWaning = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="13.718" height="13.718" viewBox="0 0 13.718 13.718">
      <path id="info" d="M6.859.5a6.859,6.859,0,1,0,6.859,6.859A6.862,6.862,0,0,0,6.859.5ZM8.118,7.72A1.259,1.259,0,0,0,6.859,6.46h0A1.259,1.259,0,0,0,5.6,7.72V10.4A1.259,1.259,0,0,0,6.859,11.66h0A1.259,1.259,0,0,0,8.118,10.4ZM6.859,3.058A1.259,1.259,0,1,0,8.118,4.318,1.26,1.26,0,0,0,6.859,3.058Z" transform="translate(0 -0.5)" fill="#ff6701" fillRule="evenodd"/>
    </svg>
  );

  let IconInfoError = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="13.718" height="13.718" viewBox="0 0 13.718 13.718">
      <path id="info" d="M6.859.5a6.859,6.859,0,1,0,6.859,6.859A6.862,6.862,0,0,0,6.859.5ZM8.118,7.72A1.259,1.259,0,0,0,6.859,6.46h0A1.259,1.259,0,0,0,5.6,7.72V10.4A1.259,1.259,0,0,0,6.859,11.66h0A1.259,1.259,0,0,0,8.118,10.4ZM6.859,3.058A1.259,1.259,0,1,0,8.118,4.318,1.26,1.26,0,0,0,6.859,3.058Z" transform="translate(0 -0.5)" fill="#c20505" fillRule="evenodd"/>
    </svg>
  );
  
  let IconNotFound = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="199" viewBox="0 0 284.52 323.75">
      <g id="not" transform="translate(-86.943 -94.771)">
        <g id="Grupo_431" data-name="Grupo 431">
          <path id="Caminho_434" data-name="Caminho 434" d="M130.36,194.26c-.88,2-1.85,4.16-2.7,6.27s-1.73,4.28-2.5,6.44a91.351,91.351,0,0,0-3.79,12.93l-.29,1.6-.1.6a2.076,2.076,0,0,1-.06.59,24.53,24.53,0,0,0,.06,2.92,58.905,58.905,0,0,0,1,6.63c.84,4.53,1.91,9.2,3,13.8l-5.13,2a92.649,92.649,0,0,1-6.09-13.59,57,57,0,0,1-2.12-7.47,26.812,26.812,0,0,1-.56-4.26v-1.21a5.984,5.984,0,0,1,.07-1.19l.2-2a63,63,0,0,1,1.37-7.65c.56-2.5,1.28-4.93,2-7.33s1.62-4.76,2.57-7.08c.47-1.16,1-2.31,1.46-3.46a38.316,38.316,0,0,1,1.64-3.48Z" fill="#e4897b"/>
          <path id="Caminho_435" data-name="Caminho 435" d="M124.71,244.69l3.9,3.81-8.51,4s-2.06-3.61-.63-6.87Z" fill="#e4897b"/>
          <path id="Caminho_436" data-name="Caminho 436" d="M131.76,254.17l-7.19,2.94-4.46-4.65,8.5-3.96,3.15,5.67Z" fill="#e4897b"/>
          <path id="Caminho_437" data-name="Caminho 437" d="M277.41,250.07A72,72,0,1,1,357,186.5,72,72,0,0,1,277.41,250.07Z" fill="#fafafa" opacity="0.4"/>
          <path id="Caminho_438" data-name="Caminho 438" d="M329.79,121.79l-109.5,87.49c-.46-1-.91-2-1.33-3a71.239,71.239,0,0,1-5.21-20.48l94.58-75.57a71.15,71.15,0,0,1,21.46,11.56Z" fill="#fafafa" opacity="0.4"/>
          <path id="Caminho_439" data-name="Caminho 439" d="M355.06,160.12,252.11,242.37a71.86,71.86,0,0,1-24.77-21.28l112.41-89.82a72,72,0,0,1,15.31,28.85Z" fill="#fafafa" opacity="0.4"/>
          <path id="Caminho_440" data-name="Caminho 440" d="M204.39,151l-.95-.32a87.681,87.681,0,0,1,6.18-14.05l.87.48a86.928,86.928,0,0,0-6.1,13.89Zm12.14-23.34-.81-.59a86.7,86.7,0,0,1,31.37-26.21l.45.9a85.74,85.74,0,0,0-31.01,25.93Zm109.59-24.53A83.6,83.6,0,0,0,316,98.54l.36-.94a85.622,85.622,0,0,1,10.22,4.68Z" fill="#407bff"/>
          <path id="Caminho_441" data-name="Caminho 441" d="M204.39,151l-.95-.32a87.681,87.681,0,0,1,6.18-14.05l.87.48a86.928,86.928,0,0,0-6.1,13.89Zm12.14-23.34-.81-.59a86.7,86.7,0,0,1,31.37-26.21l.45.9a85.74,85.74,0,0,0-31.01,25.93Zm109.59-24.53A83.6,83.6,0,0,0,316,98.54l.36-.94a85.622,85.622,0,0,1,10.22,4.68Z" opacity="0.3"/>
          <path id="Caminho_442" data-name="Caminho 442" d="M359.87,141.74a83,83,0,0,0-152.65,64.73c-6.68,2.34-13.28,4.85-19.84,7.44q-14.19,5.63-28.12,11.76c-9.28,4.11-18.52,8.3-27.67,12.68-4.59,2.15-9.12,4.42-13.69,6.62a19.79,19.79,0,0,0-6.34,4.35,19.151,19.151,0,0,0-2.57,3.37,17.25,17.25,0,0,0-2,4.63,12.72,12.72,0,0,0,7.33,14.85,17.56,17.56,0,0,0,4.86,1.26,18.878,18.878,0,0,0,4.25,0,19.76,19.76,0,0,0,7.3-2.39c4.53-2.29,9.09-4.5,13.59-6.85,9-4.59,18-9.38,26.89-14.24S189,240,197.68,234.78c6-3.63,12.06-7.34,18-11.22a83,83,0,0,0,144.21-81.82Zm-45.46,95.53a65.53,65.53,0,1,1,29.75-87.78,65.53,65.53,0,0,1-29.75,87.78Z" fill="#407bff"/>
          <path id="Caminho_443" data-name="Caminho 443" d="M359.87,141.74a83,83,0,0,0-152.65,64.73c-6.68,2.34-13.28,4.85-19.84,7.44q-14.19,5.63-28.12,11.76c-9.28,4.11-18.52,8.3-27.67,12.68-4.59,2.15-9.12,4.42-13.69,6.62a19.79,19.79,0,0,0-6.34,4.35,19.151,19.151,0,0,0-2.57,3.37,17.25,17.25,0,0,0-2,4.63,12.72,12.72,0,0,0,7.33,14.85,17.56,17.56,0,0,0,4.86,1.26,18.878,18.878,0,0,0,4.25,0,19.76,19.76,0,0,0,7.3-2.39c4.53-2.29,9.09-4.5,13.59-6.85,9-4.59,18-9.38,26.89-14.24S189,240,197.68,234.78c6-3.63,12.06-7.34,18-11.22a83,83,0,0,0,144.21-81.82Zm-45.46,95.53a65.53,65.53,0,1,1,29.75-87.78,65.53,65.53,0,0,1-29.75,87.78Z" opacity="0.3"/>
          <path id="Caminho_444" data-name="Caminho 444" d="M158,158.55c0,.59.31,1.07.7,1.07s.7-.48.7-1.07-.31-1.07-.7-1.07S158,158,158,158.55Z" fill="#263238"/>
          <path id="Caminho_445" data-name="Caminho 445" d="M158.28,159.62a21.33,21.33,0,0,0,2.83,5.07,3.41,3.41,0,0,1-2.83.53Z" fill="#de5753"/>
          <path id="Caminho_446" data-name="Caminho 446" d="M155.93,155.26a.37.37,0,0,1-.29-.14.36.36,0,0,1,.08-.49,3.43,3.43,0,0,1,3.13-.53.35.35,0,1,1-.25.65,2.73,2.73,0,0,0-2.47.45.36.36,0,0,1-.2.06Z" fill="#263238"/>
          <path id="Caminho_447" data-name="Caminho 447" d="M137.8,163c1,5.37,2.1,15.22-1.66,18.81,0,0,1.47,5.45,11.46,5.45,11,0,5.24-5.45,5.24-5.45-6-1.44-5.83-5.88-4.79-10.06Z" fill="#e4897b"/>
          <path id="Caminho_448" data-name="Caminho 448" d="M131.65,184.64c1.19-1.85-.81-6.34-.81-6.34s17.68-4.45,24.91,2.38c1.5,1.41-.16,3.49-.16,3.49Z" fill="#407bff"/>
          <path id="Caminho_449" data-name="Caminho 449" d="M131.65,184.64c1.19-1.85-.81-6.34-.81-6.34s17.68-4.45,24.91,2.38c1.5,1.41-.16,3.49-.16,3.49Z" opacity="0.1"/>
          <path id="Caminho_450" data-name="Caminho 450" d="M102.4,388.67c-.93,0-2.42-1.55-2.86-2.13a.17.17,0,0,1,0-.19.18.18,0,0,1,.16-.09c.11,0,2.56.22,3.23,1.09a.81.81,0,0,1,.16.66.72.72,0,0,1-.52.65Zm-2.32-2c.73.84,1.9,1.79,2.4,1.67,0,0,.2-.05.26-.37a.45.45,0,0,0-.1-.38,4.67,4.67,0,0,0-2.56-.94Z" fill="#407bff"/>
          <path id="Caminho_451" data-name="Caminho 451" d="M100.19,386.65a2.681,2.681,0,0,1-.54,0,.16.16,0,0,1-.14-.11.2.2,0,0,1,0-.18c.07-.06,1.54-1.59,2.65-1.59a.94.94,0,0,1,.71.3.57.57,0,0,1,.14.7c-.29.56-1.82.88-2.82.88Zm-.13-.35c1,0,2.45-.31,2.69-.75,0,0,.07-.13-.09-.29a.59.59,0,0,0-.46-.19,4.23,4.23,0,0,0-2.14,1.23Z" fill="#407bff"/>
          <path id="Caminho_452" data-name="Caminho 452" d="M168.39,411.37a13.254,13.254,0,0,1-2.26-.21A.16.16,0,0,1,166,411a.18.18,0,0,1,.08-.18c.11-.06,2.67-1.53,3.9-1.19a.86.86,0,0,1,.55.4.66.66,0,0,1,0,.76C170.24,411.24,169.33,411.37,168.39,411.37Zm-1.69-.47c1.36.2,3.22.19,3.56-.29.05-.06.09-.17,0-.38a.56.56,0,0,0-.34-.25C169.14,409.78,167.59,410.44,166.7,410.9Z" fill="#407bff"/>
          <path id="Caminho_453" data-name="Caminho 453" d="M166.17,411.17a.18.18,0,0,1-.1,0A.16.16,0,0,1,166,411a4.88,4.88,0,0,1,1.14-2.83,1.25,1.25,0,0,1,1-.29.577.577,0,0,1,.65.48c.13.83-1.62,2.42-2.51,2.82Zm1.79-3a.91.91,0,0,0-.6.22,4,4,0,0,0-1,2.27c.9-.55,2.1-1.79,2-2.29,0,0,0-.17-.34-.2Z" fill="#407bff"/>
          <path id="Caminho_454" data-name="Caminho 454" d="M158.09,410.99h7.35l.73-17.01h-7.35Z" fill="#e4897b"/>
          <path id="Caminho_455" data-name="Caminho 455" d="M92.64,381.79l6.14,4.02,13.56-11.45-6.15-4.03L92.64,381.79Z" fill="#e4897b"/>
          <path id="Caminho_456" data-name="Caminho 456" d="M99.79,385.43l-6.38-5.23a.63.63,0,0,0-.8,0l-5.26,4.13a1.08,1.08,0,0,0,0,1.69c2.26,1.79,3.45,2.55,6.26,4.85,1.73,1.41,6,5.73,8.41,7.68s4.62-.15,3.82-1.16c-3.59-4.55-5-8.44-5.41-10.85a1.81,1.81,0,0,0-.64-1.11Z" fill="#263238"/>
          <path id="Caminho_457" data-name="Caminho 457" d="M165.39,410.14h-8a.65.65,0,0,0-.63.5l-1.45,6.53a1.07,1.07,0,0,0,1.06,1.31c2.89-.05,7.07-.22,10.71-.22,4.25,0,7.93.23,12.91.23,3,0,3.86-3.05,2.59-3.32-5.74-1.26-10.44-1.39-15.4-4.46a3.44,3.44,0,0,0-1.79-.57Z" fill="#263238"/>
          <path id="Caminho_458" data-name="Caminho 458" d="M121.66,184c-6.37,3-10,23.1-10,23.1l15.57,6.21a115.829,115.829,0,0,0,5.51-17.15c2.14-9.26-4.56-15.26-11.08-12.16Z" fill="#263238"/>
          <path id="Caminho_459" data-name="Caminho 459" d="M129.92,195.33c-3.85,3.9-5.61,11.54-6.38,16.51l3.73,1.49a116.7,116.7,0,0,0,5.4-16.65C132.34,194.56,131.52,193.71,129.92,195.33Z" fill="#203048"/>
          <path id="Caminho_460" data-name="Caminho 460" d="M164.14,183.86s7.82,8.84.44,63.5H125.29c-.27-6,3.52-35.45-2.3-63.87a100.562,100.562,0,0,1,13.15-1.66,140.866,140.866,0,0,1,16.7,0,74.191,74.191,0,0,1,11.3,2.03Z" fill="#263238"/>
          <path id="Caminho_461" data-name="Caminho 461" d="M167.73,205.08l-6.62-11.41a58.31,58.31,0,0,0-1.55,16.27c.18,3.92,3.77,18.44,7,20.56A230.846,230.846,0,0,0,167.73,205.08Z" fill="#203048"/>
          <path id="Caminho_462" data-name="Caminho 462" d="M168.65,191.67c1,4.86,2,9.84,3.07,14.72s2.18,9.78,3.47,14.51c.33,1.17.64,2.37,1,3.49l.27.86.13.43v-.05c-.07-.09-.15-.1-.09,0a3.35,3.35,0,0,0,1.52.95,17.839,17.839,0,0,0,2.91.81,77.79,77.79,0,0,0,14.3.93l.95,5.44c-1.34.43-2.59.74-3.91,1.05s-2.62.57-3.95.77a42.73,42.73,0,0,1-8.33.56,22.611,22.611,0,0,1-4.6-.6,13.391,13.391,0,0,1-5.28-2.45,9.65,9.65,0,0,1-2.47-3c-.16-.3-.3-.64-.43-.93l-.18-.47-.37-1c-.51-1.27-.93-2.52-1.39-3.77-1.69-5-3.1-10.05-4.34-15.09s-2.31-10.07-3.2-15.24Z" fill="#e4897b"/>
          <path id="Caminho_463" data-name="Caminho 463" d="M154.33,189.22c-2.07,6.71,6.32,26.72,6.32,26.72l16.71-4.2s-.64-10.4-4.45-18.65c-5.74-12.44-16.1-11.92-18.58-3.87Z" fill="#263238"/>
          <path id="Caminho_464" data-name="Caminho 464" d="M195.09,228.32l8.32-.21-4,8.66s-3.39.45-6.17-4.12l-2.93-3.5,3.37-.67A7.52,7.52,0,0,1,195.09,228.32Z" fill="#e4897b"/>
          <path id="Caminho_465" data-name="Caminho 465" d="M208.66,229.72l-4.76,7.18-4.49-.13,4-8.66,5.25,1.61Z" fill="#e4897b"/>
          <path id="Caminho_466" data-name="Caminho 466" d="M166.17,393.98l-.38,8.77h-7.35l.38-8.77Z" fill="#ce6f64"/>
          <path id="Caminho_467" data-name="Caminho 467" d="M106.19,370.33l6.15,4.03-7,5.9-6.14-4.02Z" fill="#ce6f64"/>
          <path id="Caminho_468" data-name="Caminho 468" d="M138.26,157.28c.44,7.27.33,11.56,4,15.29,5.52,5.63,14.51,2.3,16.25-5,1.57-6.54.59-17.34-6.53-20.24a10,10,0,0,0-13.72,9.95Z" fill="#e4897b"/>
          <path id="Caminho_469" data-name="Caminho 469" d="M129.7,156c1.81,6.63,6.07,14.27,11.58,14.76,6.84.6,9.93-6.47,10.83-13.77,4.1-2.81,1.95-5.9,6.06-6.29,5.28-.5,3.59-10.16-3.36-11.71,0,0,2.33,4.35-2.56,2.15a28.22,28.22,0,0,0-14.94-2.59s6.24,3.12.14,4.51-7.92,5.15-5.11,7.42A4.241,4.241,0,0,0,129.7,156Z" fill="#263238"/>
          <path id="Caminho_470" data-name="Caminho 470" d="M147.94,158.49a8.22,8.22,0,0,0,2.07,4.87c1.6,1.77,3.27.67,3.55-1.48.26-1.93-.27-5.18-2.23-6.16s-3.52.59-3.39,2.77Z" fill="#e4897b"/>
          <path id="Caminho_471" data-name="Caminho 471" d="M150.64,247.36s7.16,51.85,1.52,72.63c-8.94,32.9-43.12,60.67-43.12,60.67l-11.59-7.6s29.11-23.45,32.67-51c3.2-24.73-4.83-54.64-4.83-74.71Z" fill="#407bff"/>
          <path id="Caminho_472" data-name="Caminho 472" d="M150.64,247.36s7.16,51.85,1.52,72.63c-8.94,32.9-43.12,60.67-43.12,60.67l-11.59-7.6s29.11-23.45,32.67-51c3.2-24.73-4.83-54.64-4.83-74.71Z" opacity="0.1"/>
          <path id="Caminho_473" data-name="Caminho 473" d="M153.6,277a48.4,48.4,0,0,0-6.27-6c1.22,16.83,2.22,41.76,1.11,59.47A76.414,76.414,0,0,0,152.16,320C154.91,309.85,154.62,292.32,153.6,277Z" fill="#407bff"/>
          <path id="Caminho_474" data-name="Caminho 474" d="M153.6,277a48.4,48.4,0,0,0-6.27-6c1.22,16.83,2.22,41.76,1.11,59.47A76.414,76.414,0,0,0,152.16,320C154.91,309.85,154.62,292.32,153.6,277Z" opacity="0.3"/>
          <path id="Caminho_475" data-name="Caminho 475" d="M113.71,378.07c.05,0-4.49,3-4.49,3l-12.66-8.3,4-3.31Z" fill="#407bff"/>
          <path id="Caminho_476" data-name="Caminho 476" d="M113.71,378.07c.05,0-4.49,3-4.49,3l-12.66-8.3,4-3.31Z" opacity="0.3"/>
          <path id="Caminho_477" data-name="Caminho 477" d="M164.58,247.36s11.06,46.9,11.84,69.31c.88,25.12-7.89,84.38-7.89,84.38H156.08s-1.53-59.72-2.08-82.6c-.61-24.95-15.25-71.09-15.25-71.09Z" fill="#407bff"/>
          <path id="Caminho_478" data-name="Caminho 478" d="M164.58,247.36s11.06,46.9,11.84,69.31c.88,25.12-7.89,84.38-7.89,84.38H156.08s-1.53-59.72-2.08-82.6c-.61-24.95-15.25-71.09-15.25-71.09Z" opacity="0.1"/>
          <path id="Caminho_479" data-name="Caminho 479" d="M170.92,396.1c.06,0-.7,5.16-.7,5.16H155.08l-.41-4.61Z" fill="#407bff"/>
          <path id="Caminho_480" data-name="Caminho 480" d="M170.92,396.1c.06,0-.7,5.16-.7,5.16H155.08l-.41-4.61Z" opacity="0.3"/>
        </g>
        <g id="Character">
          <path id="Caminho_481" data-name="Caminho 481" d="M356,202.29c.85.8,1.52,1.48,2.22,2.24s1.36,1.5,2,2.27c1.3,1.53,2.52,3.14,3.7,4.76a76.268,76.268,0,0,1,6.27,10.26l.37.73a7.048,7.048,0,0,1,.45,1.1,9.12,9.12,0,0,1,.44,2.26,11,11,0,0,1-.55,4,20.161,20.161,0,0,1-3.21,5.92,42.681,42.681,0,0,1-8.8,8.46l-2.49-2.86c1.17-1.37,2.39-2.84,3.48-4.31a36.756,36.756,0,0,0,3-4.5,15.628,15.628,0,0,0,1.82-4.42,4.58,4.58,0,0,0,.07-1.7,2.217,2.217,0,0,0-.18-.55,1.587,1.587,0,0,0-.13-.22l-.32-.54a105.06,105.06,0,0,0-6.27-9.11c-1.11-1.47-2.3-2.88-3.5-4.27-.59-.7-1.2-1.38-1.81-2.06s-1.27-1.36-1.8-1.89Z" fill="#e4897b"/>
          <path id="Caminho_482" data-name="Caminho 482" d="M357.36,240.59l-7.47-1.22,3.91,6.54s4.81-.17,6.18-3Z" fill="#e4897b"/>
          <path id="Caminho_483" data-name="Caminho 483" d="M344.37,242.39l3.56,5.31,5.87-1.79-3.91-6.54-5.52,3.02Z" fill="#e4897b"/>
          <path id="Caminho_484" data-name="Caminho 484" d="M357,411.28a2.15,2.15,0,0,1-1.43-.39,1,1,0,0,1-.29-.94.59.59,0,0,1,.35-.5c.87-.39,3.15,1.07,3.41,1.24a.181.181,0,0,1-.06.32,8.848,8.848,0,0,1-1.98.27Zm-1-1.55a.722.722,0,0,0-.22,0,.26.26,0,0,0-.15.23.67.67,0,0,0,.18.63c.38.34,1.36.39,2.67.13a6.589,6.589,0,0,0-2.48-.99Z" fill="#407bff"/>
          <path id="Caminho_485" data-name="Caminho 485" d="M359,411h-.09c-.71-.41-2.08-2-1.91-2.87a.65.65,0,0,1,.6-.5.9.9,0,0,1,.75.24c.82.72.83,2.88.83,3a.19.19,0,0,1-.09.15Zm-1.28-3h-.08c-.22,0-.27.12-.29.21-.1.51.75,1.73,1.46,2.3a4.09,4.09,0,0,0-.69-2.36.57.57,0,0,0-.45-.15Z" fill="#407bff"/>
          <path id="Caminho_486" data-name="Caminho 486" d="M322.92,411.28a2.77,2.77,0,0,1-1.81-.46,1,1,0,0,1-.33-.86.58.58,0,0,1,.3-.47c.93-.52,3.91,1,4.25,1.19a.2.2,0,0,1,.09.19.17.17,0,0,1-.14.14,12.469,12.469,0,0,1-2.36.27Zm-1.33-1.55a.62.62,0,0,0-.33.07.21.21,0,0,0-.12.19.65.65,0,0,0,.2.57c.46.41,1.69.48,3.35.2a9.73,9.73,0,0,0-3.1-1.03Z" fill="#407bff"/>
          <path id="Caminho_487" data-name="Caminho 487" d="M325.25,411h-.08c-.89-.4-2.66-2-2.52-2.87,0-.2.18-.45.67-.5a1.32,1.32,0,0,1,1,.31c.94.78,1.12,2.8,1.12,2.89a.17.17,0,0,1-.07.16.19.19,0,0,1-.12.01Zm-1.79-3h-.11c-.32,0-.34.16-.35.2-.08.51,1.12,1.78,2,2.33a4.16,4.16,0,0,0-1-2.31A.93.93,0,0,0,323.46,408Z" fill="#407bff"/>
          <path id="Caminho_488" data-name="Caminho 488" d="M337.07,179.3c-.29,4.52-1.07,13.7,2.4,16.24,0,0-.63,4.63-8.82,5.71-9,1.17-4.89-3.91-4.89-3.91,4.77-1.82,4.16-5.45,2.86-8.77Z" fill="#e4897b"/>
          <path id="Caminho_489" data-name="Caminho 489" d="M318.44,171.31a.34.34,0,0,1-.26-.12,2.83,2.83,0,0,0-2.35-1,.35.35,0,0,1-.41-.3.36.36,0,0,1,.31-.4,3.51,3.51,0,0,1,3,1.26.36.36,0,0,1-.27.59Z" fill="#263238"/>
          <path id="Caminho_490" data-name="Caminho 490" d="M316.88,175.74a16.28,16.28,0,0,1-1.63,4.1,2.58,2.58,0,0,0,2.19.12Z" fill="#de5753"/>
          <path id="Caminho_491" data-name="Caminho 491" d="M317.28,174.64c.08.6-.17,1.12-.56,1.18s-.78-.4-.85-1,.17-1.12.56-1.17S317.21,174,317.28,174.64Z" fill="#263238"/>
          <path id="Caminho_492" data-name="Caminho 492" d="M316.61,173.66l-1.49-.23S316,174.46,316.61,173.66Z" fill="#263238"/>
          <path id="Caminho_493" data-name="Caminho 493" d="M333.49,410.84h-7.5l-.5-17.35h7.5Z" fill="#e4897b"/>
          <path id="Caminho_494" data-name="Caminho 494" d="M367.54,410.84h-7.5l-2.41-17.35h7.5Z" fill="#e4897b"/>
          <path id="Caminho_495" data-name="Caminho 495" d="M359.58,410H368a.59.59,0,0,1,.59.51l1,6.67a1.2,1.2,0,0,1-1.2,1.33c-2.93-.05-4.35-.22-8.05-.22-2.28,0-5.61.23-8.75.23s-3.31-3.11-2-3.39c5.87-1.26,6.8-3,8.77-4.67A2,2,0,0,1,359.58,410Z" fill="#263238"/>
          <path id="Caminho_496" data-name="Caminho 496" d="M325.74,410h8.41a.6.6,0,0,1,.6.51l1,6.67a1.2,1.2,0,0,1-1.2,1.33c-2.93-.05-4.35-.22-8.05-.22-2.28,0-7,.23-10.14.23s-3.31-3.11-2-3.39c5.87-1.26,8.18-3,10.16-4.67A2,2,0,0,1,325.74,410Z" fill="#263238"/>
          <path id="Caminho_497" data-name="Caminho 497" d="M325.49,393.49l.26,8.95h7.5l-.26-8.95Z" fill="#ce6f64"/>
          <path id="Caminho_498" data-name="Caminho 498" d="M365.13,393.49h-7.5l1.25,8.95h7.49Z" fill="#ce6f64"/>
          <path id="Caminho_499" data-name="Caminho 499" d="M337.17,169.94c.51,7.42.88,10.55-2.34,14.82-4.85,6.41-14.07,5.42-16.79-1.7-2.45-6.41-2.87-17.46,3.95-21.34a10.15,10.15,0,0,1,15.18,8.22Z" fill="#e4897b"/>
          <path id="Caminho_500" data-name="Caminho 500" d="M341.49,176.83c4.16,2.32,7.25-3.4,7.25-3.4s-7.46.81-6.79-3.63c.88-5.89-3.34-13.05-13.92-12.13-.59.05-1.15.12-1.7.21a5.44,5.44,0,0,0-6.58,1.52c-3,.25-7.05,3.83-4.3,8.56a4.35,4.35,0,0,1,.88-1.47c.23,2.77,2.65,7.17,5.67,7.46.65,3.79-1.87,8.18-1,12.14,1.29,5.71,10.92,7,10.92,7a4.17,4.17,0,0,1,.56-4.41c6.54,3,10.85.07,10.85.07-4.46-1.66-3.11-4.62-3.11-4.62a6.51,6.51,0,0,0,8.49-3.09,7.68,7.68,0,0,1-7.22-4.21Z" fill="#263238"/>
          <path id="Caminho_501" data-name="Caminho 501" d="M324.61,175.09a5.4,5.4,0,0,1-1.5,3.87c-1.32,1.37-2.94.41-3.38-1.34-.39-1.58-.17-4.19,1.55-4.91a2.44,2.44,0,0,1,3.33,2.38Z" fill="#e4897b"/>
          <path id="Caminho_502" data-name="Caminho 502" d="M330.84,244.22s1.9,49.36,6.42,78.34c3.64,23.42,19.81,78.14,19.81,78.14h10.22s-8.43-52.85-10.28-76c-4.68-58.59,7.47-71.17-4-83.37Z" fill="#263238"/>
          <path id="Caminho_503" data-name="Caminho 503" d="M339.38,259.83s-4.62-.8-7.32,8c1,16.67,2.71,38.79,5.2,54.73.55,3.55,1.39,7.83,2.42,12.52-1.95-28.16-.3-75.25-.3-75.25Z" fill="#161f33"/>
          <path id="Caminho_504" data-name="Caminho 504" d="M322.87,245.26s-8.42,54.61-7.93,77.46c.51,23.77,9.22,78,9.22,78h10.13s-1.6-52.82-.71-76.16c1-25.45,11.39-82.17,11.39-82.17Z" fill="#263238"/>
          <path id="Caminho_505" data-name="Caminho 505" d="M368.77,401.18H355.18l-1.06-4.56,14.55.1Z" fill="#407bff"/>
          <path id="Caminho_506" data-name="Caminho 506" d="M368.77,401.18H355.18l-1.06-4.56,14.55.1Z" opacity="0.2"/>
          <path id="Caminho_507" data-name="Caminho 507" d="M352.5,195.55c3.91.38,9.85,11.49,9.85,11.49l-9.87,7.57s-8.17-7.17-7.2-11.2c1.01-4.19,3.47-8.21,7.22-7.86Z" fill="#407bff"/>
          <path id="Caminho_508" data-name="Caminho 508" d="M352.5,195.55c3.91.38,9.85,11.49,9.85,11.49l-9.87,7.57s-8.17-7.17-7.2-11.2c1.01-4.19,3.47-8.21,7.22-7.86Z" opacity="0.1"/>
          <path id="Caminho_509" data-name="Caminho 509" d="M349.55,200.33a10.13,10.13,0,0,0-3.06-.34,16.908,16.908,0,0,0-1.21,3.42c-1,4,7.2,11.2,7.2,11.2l.73-.56Z" fill="#407bff"/>
          <path id="Caminho_510" data-name="Caminho 510" d="M349.55,200.33a10.13,10.13,0,0,0-3.06-.34,16.908,16.908,0,0,0-1.21,3.42c-1,4,7.2,11.2,7.2,11.2l.73-.56Z" opacity="0.3"/>
          <path id="Caminho_511" data-name="Caminho 511" d="M319.52,211c-2.85,7.21-5.85,14.54-8.24,21.77-.16.46-.29.9-.43,1.35l-.34,1.11a1.83,1.83,0,0,0,0,1,7.52,7.52,0,0,0,2.59,3.1,42.191,42.191,0,0,0,9.69,5.19l-.9,3.68a34.69,34.69,0,0,1-12.08-3.94,12.93,12.93,0,0,1-5.39-5.46,8.37,8.37,0,0,1-.69-4.76,5.569,5.569,0,0,1,.1-.59l.1-.42.17-.76c.12-.51.24-1,.37-1.5,1-4,2.28-7.8,3.63-11.57s2.76-7.49,4.35-11.2Z" fill="#e4897b"/>
          <path id="Caminho_512" data-name="Caminho 512" d="M313.45,200.88s-3.38,1.71,9.42,44.38l30.18-3.94c-2.12-12.22-3.12-19.79-.55-45.77a91,91,0,0,0-13,0,95.5,95.5,0,0,0-13.71,1.8c-5.79,1.29-12.34,3.53-12.34,3.53Z" fill="#407bff"/>
          <path id="Caminho_513" data-name="Caminho 513" d="M313.45,200.88s-3.38,1.71,9.42,44.38l30.18-3.94c-2.12-12.22-3.12-19.79-.55-45.77a91,91,0,0,0-13,0,95.5,95.5,0,0,0-13.71,1.8c-5.79,1.29-12.34,3.53-12.34,3.53Z" opacity="0.1"/>
          <path id="Caminho_514" data-name="Caminho 514" d="M353.27,239.49,355,242c.13.2-.09.44-.43.49l-31.65,4.14c-.27,0-.52-.07-.56-.24l-.62-2.67c-.05-.18.17-.37.47-.41l30.56-4a.55.55,0,0,1,.5.18Z" fill="#407bff"/>
          <path id="Caminho_515" data-name="Caminho 515" d="M353.27,239.49,355,242c.13.2-.09.44-.43.49l-31.65,4.14c-.27,0-.52-.07-.56-.24l-.62-2.67c-.05-.18.17-.37.47-.41l30.56-4a.55.55,0,0,1,.5.18Z" opacity="0.2"/>
          <path id="Caminho_516" data-name="Caminho 516" d="M349.61,243.44l.82-.11c.16,0,.27-.12.25-.22l-.84-3.46a.29.29,0,0,0-.34-.14l-.82.1c-.17,0-.28.12-.25.23l.84,3.45c.02.11.18.17.34.15Z" fill="#263238"/>
          <path id="Caminho_517" data-name="Caminho 517" d="M329.77,246l.82-.11c.16,0,.28-.12.25-.22l-.84-3.46a.31.31,0,0,0-.34-.15l-.82.11c-.16,0-.28.12-.25.22l.84,3.46C329.45,246,329.61,246.06,329.77,246Z" fill="#263238"/>
          <path id="Caminho_518" data-name="Caminho 518" d="M314.86,215.36c.83,3.86,2,8.75,3.71,14.94l.37-15.69Z" fill="#407bff"/>
          <path id="Caminho_519" data-name="Caminho 519" d="M314.86,215.36c.83,3.86,2,8.75,3.71,14.94l.37-15.69Z" opacity="0.3"/>
          <path id="Caminho_520" data-name="Caminho 520" d="M313.45,200.88c-3.75,1.51-7,14.34-7,14.34l12.9,6.28s6.23-15.69,3.44-18.75S317.77,199.15,313.45,200.88Z" fill="#407bff"/>
          <path id="Caminho_521" data-name="Caminho 521" d="M313.45,200.88c-3.75,1.51-7,14.34-7,14.34l12.9,6.28s6.23-15.69,3.44-18.75S317.77,199.15,313.45,200.88Z" opacity="0.1"/>
          <path id="Caminho_522" data-name="Caminho 522" d="M336.02,401.18H322.43l-1.06-4.56,15.13.1Z" fill="#407bff"/>
          <path id="Caminho_523" data-name="Caminho 523" d="M336.02,401.18H322.43l-1.06-4.56,15.13.1Z" opacity="0.2"/>
          <path id="Caminho_524" data-name="Caminho 524" d="M323,243.28l-1.09-.45a1.64,1.64,0,0,0-2.24,1.76l.79,5.21a1.78,1.78,0,0,0,2.36,1.42,4,4,0,0,0,1.1-.61,3,3,0,0,0,1.08-2.48l-.11-2.16a3,3,0,0,0-1.89-2.69Z" fill="#e4897b"/>
        </g>
      </g>
    </svg>
  );

  let IconBoleto = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="26" viewBox="0 0 209.591 159.649">
      <g id="Barcode_1_" transform="translate(0 -61)">
        <g id="Grupo_432" dataName="Grupo 432" transform="translate(0 61)">
          <path id="Caminho_525" dataName="Caminho 525" d="M12.281,97.842H0V61H37.252V73.281H12.281Z" transform="translate(0 -61)" fill="#fff"/>
        </g>
        <g id="Grupo_433" dataName="Grupo 433" transform="translate(172.339 61)">
          <path id="Caminho_526" dataName="Caminho 526" d="M458.252,97.842H445.971V73.281H421V61h37.252Z" transform="translate(-421 -61)" fill="#fff"/>
        </g>
        <g id="Grupo_434" dataName="Grupo 434" transform="translate(0 183.807)">
          <path id="Caminho_527" dataName="Caminho 527" d="M37.252,397.842H0V361H12.281v24.561H37.252Z" transform="translate(0 -361)" fill="#fff"/>
        </g>
        <g id="Grupo_435" dataName="Grupo 435" transform="translate(172.339 183.807)">
          <path id="Caminho_528" dataName="Caminho 528" d="M458.252,397.842H421V385.561h24.971V361h12.281Z" transform="translate(-421 -361)" fill="#fff"/>
        </g>
        <g id="Grupo_436" dataName="Grupo 436" transform="translate(24.971 85.561)">
          <path id="Caminho_529" dataName="Caminho 529" d="M97.842,231.526H61V121H97.842Z" transform="translate(-61 -121)" fill="#fff"/>
        </g>
        <g id="Grupo_437" dataName="Grupo 437" transform="translate(98.655 85.561)">
          <path id="Caminho_530" dataName="Caminho 530" d="M277.842,231.526H241V121h36.842Z" transform="translate(-241 -121)" fill="#fff"/>
        </g>
        <g id="Grupo_438" dataName="Grupo 438" transform="translate(74.094 85.561)">
          <path id="Caminho_531" dataName="Caminho 531" d="M181,121h12.281V231.526H181Z" transform="translate(-181 -121)" fill="#fff"/>
        </g>
        <g id="Grupo_439" dataName="Grupo 439" transform="translate(147.778 85.561)">
          <path id="Caminho_532" dataName="Caminho 532" d="M361,121h12.281V231.526H361Z" transform="translate(-361 -121)" fill="#fff"/>
        </g>
        <g id="Grupo_440" dataName="Grupo 440" transform="translate(172.339 85.561)">
          <path id="Caminho_533" dataName="Caminho 533" d="M421,121h12.281V231.526H421Z" transform="translate(-421 -121)" fill="#fff"/>
        </g>
      </g>
    </svg>
  );

  let IconMoney = props =>(
    <svg id="coin" xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 141.367 141.367">
      <g id="Grupo_442" dataName="Grupo 442">
        <g id="Grupo_441" dataName="Grupo 441">
          <path id="Caminho_534" dataName="Caminho 534" d="M70.684,0a70.684,70.684,0,1,0,70.684,70.684A70.686,70.686,0,0,0,70.684,0Zm20.3,98.55a22.735,22.735,0,0,1-11.781,7.563c-2.036.553-2.967,1.629-2.851,3.752.087,2.094,0,4.16-.029,6.254a2.59,2.59,0,0,1-2.792,2.909c-1.193.029-2.385.058-3.578.058-1.047,0-2.094,0-3.141-.029-1.978-.029-2.909-1.164-2.909-3.083-.029-1.513-.029-3.054-.029-4.567-.029-3.374-.145-3.491-3.374-4.014a42.874,42.874,0,0,1-11.984-3.432c-2.967-1.454-3.287-2.182-2.443-5.323.64-2.327,1.28-4.654,2.007-6.952C48.606,90,49.1,89.242,50,89.242a5.1,5.1,0,0,1,2.094.727,38.807,38.807,0,0,0,13.322,3.985,21.344,21.344,0,0,0,2.356.145,15.718,15.718,0,0,0,6.37-1.309c5.207-2.269,6.021-8.29,1.629-11.9A21.24,21.24,0,0,0,70.8,77.985c-4.567-2.007-9.308-3.52-13.613-6.108-6.981-4.189-11.4-9.919-10.879-18.413.582-9.6,6.021-15.591,14.835-18.791,3.636-1.309,3.665-1.28,3.665-5.061,0-1.28-.029-2.56.029-3.869.087-2.851.553-3.345,3.4-3.432h3.316c5.41,0,5.41.233,5.439,6.079.029,4.305.029,4.305,4.305,4.974a37.878,37.878,0,0,1,9.424,2.822A2.738,2.738,0,0,1,92.5,39.85c-.756,2.618-1.483,5.265-2.3,7.854-.524,1.571-1.018,2.3-1.949,2.3a4.672,4.672,0,0,1-1.978-.611A29.742,29.742,0,0,0,73.1,46.366c-.582,0-1.193.029-1.774.058a11.736,11.736,0,0,0-3.985.814c-4.538,1.978-5.265,6.981-1.4,10.064a26.332,26.332,0,0,0,6.487,3.636,116.959,116.959,0,0,1,11.839,5.352C96.252,72.982,99.51,88.195,90.987,98.55Z" fill="#fff"/>
        </g>
      </g>
    </svg>
  );
  
  let history = useHistory();

  const [loaderPage, setloaderPage] = useState(false);
  const [tabPage, settabPage] = useState("1");
  const [faturas, setfaturas] = useState([]);
  const [permissoesdoUsuario, setpermissoesdoUsuario] = useState([]);
  const [showModalMercadoPago, setshowModalMercadoPago] = useState(false);
  const [dadosEmpresa, setdadosEmpresa] = useState([]);
  const [enderecos, setenderecos] = useState([]);
  const [faturaAPagar, setfaturaAPagar] = useState([]);
  const [detalhesPlanoEmpresa, setdetalhesPlanoEmpresa] = useState([]);
  
  
  const getFaturas = async(status='1,2')=>{
    setloaderPage(true);

    //GET DADOS USER
    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let response = await api.get(`listagemFaturas?status=${status}`, {
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    });

    setfaturas(response.data);
    setloaderPage(false);
  }

  const getDetalhesPlanoEmpresa = async()=>{
    //GET DADOS USER
    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let response = await api.post('detalhesPlanoEmpresa', { }, 
    {
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    })
        
    let data = response.data;
    setdetalhesPlanoEmpresa(data);
  }

  const permissoesUsuario = async()=>{

    //GET DADOS USER
    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let permissoes = await api.post('permissoesUsuario', {}, 
    {
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    });

    setpermissoesdoUsuario(permissoes.data.faturas);
  }

  const getDadosEmpresa = async()=>{
    //GET DADOS USER
    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let response = await api.post('dadosEmpresa', { }, 
    {
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    })
        
    setdadosEmpresa(response.data);
  }

  const getEnderecosEmpresa = async()=>{

    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    //ENDEREÇOS
    let enderecosEmpresa = await api.post('listagemEnderecosEmpresa', {}, 
    {
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    })

    setenderecos(enderecosEmpresa.data);
  }

  useEffect(async()=>{
    await getFaturas();
    await permissoesUsuario();
    await getDadosEmpresa();
    await getEnderecosEmpresa();
    await getDetalhesPlanoEmpresa();

    // eslint-disable-next-line react-hooks/exhaustive-deps   
  },[]);

  useEffect(async()=>{
    if(tabPage === "1"){
      await getFaturas("1,2");
    }else{
      await getFaturas("3");
    }

  },[tabPage, showModalMercadoPago]); 

  const handleChangeTabPage = (event, newValue) => {
    settabPage(newValue);
  };

  const handlePagarFatura = (fatura) => {
    setfaturaAPagar(fatura);
    setshowModalMercadoPago(true);
  }

  function formatarCicloPagamento(ciclo) {
    switch (ciclo) {
      case 1:
        return "Mensal";
      case 2:
        return "Bimestral";
      case 3:
        return "Trimestral";
      case 4:
        return "Quadrimestral";
      case 6:
        return "Semestral";
      case 12:
        return "Anual";
      case 24:
        return "Bienal";
      case 36:
        return "Trienal";
      default:
        return `${ciclo} meses`; // Para outros casos, exibe o número de meses
    }
  }

  return (
    <>
  
      <ModalMercadoPago fatura={faturaAPagar} dadosEmpresa={dadosEmpresa} enderecos={enderecos} visible={showModalMercadoPago} change={setshowModalMercadoPago} />

      <Backdrop
        sx={{ color: '#fff', zIndex: '2000' }}
        open={loaderPage}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <HelmetProvider>
        <Helmet>
            <title>Nexcar - Faturas</title>
        </Helmet>
      </HelmetProvider>

      <div>
        <div className='container-page'>
          <div className='container'>
              <div className='session-faturas'>
                <div className='container-plano'>
                  <aside>
                    <h6>Plano</h6>
                    <h3>{detalhesPlanoEmpresa.plano || "Plano não especificado"}</h3>
                  </aside>

                  <div className="d-info">
                    <b>Ciclo de pagamento</b>
                    <p>{formatarCicloPagamento(detalhesPlanoEmpresa.ciclo)}</p>
                  </div>

                  <div className="d-info">
                    <b>Vencimento</b>
                    <p>dia {detalhesPlanoEmpresa.vencimento}</p>
                  </div>

                  <div className="d-info">
                    <b>Valor</b>

                    {
                      detalhesPlanoEmpresa.valorPlano === detalhesPlanoEmpresa.valorPagar ?
                      <p>{formataDinheiro(detalhesPlanoEmpresa.valorPlano)}</p>
                      :
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <p style={{ fontSize: 14, textDecoration: 'line-through', marginRight: 9 }}>{formataDinheiro(detalhesPlanoEmpresa.valorPlano)}</p>
                        <p>{formataDinheiro(detalhesPlanoEmpresa.valorPagar)}</p>
                      </div>
                    }

                    
                  </div>

                  <div className='container-funcionalidades'>
                    <h3>Funcionalidades</h3>

                    <article>
                      {detalhesPlanoEmpresa.funcionalidades ? (
                        detalhesPlanoEmpresa.funcionalidades.map((funcionalidade, index) => (
                          <p key={index}> { funcionalidade.has ? '✔️' : '❌'}  {funcionalidade.nome}</p>
                        ))
                      ) : (
                        <p style={{ textAlign: 'center', fontSize: 13.5 }}>Nenhuma funcionalidade disponível.</p>
                      )}
                    </article>
                  </div>
                </div>
                
                <div className='container-faturas'>
                  <aside>
                      <h3>Faturas</h3>
                      <p>Aqui você pode consultar detalhes e fazer o pagamento das suas faturas</p>
                  </aside>

                  <TabContext value={tabPage}>
                    <Box
                      sx={{
                        borderBottom: 1,
                        borderColor: "divider",
                        width: "100%",
                        marginTop: '16px'
                      }}
                    >
                      <Tabs
                        value={tabPage}
                        onChange={handleChangeTabPage}
                        aria-label="estoque-tabs"
                        textColor="primary"
                        indicatorColor="primary"
                        variant="scrollable"
                      >
                        <Tab label="A pagar" value="1" />
                        <Tab label="Pagas" value="2" />
                      </Tabs>
                    </Box>

                    <TabPanel value="1" style={{ width: "100%" }}>
                      <div style={{ width: "100%", display: 'flex', flexDirection: 'column', gap: '16px' }}>
                      {faturas.length > 0 ? (
                        faturas.map(fatura => {
                          const vencimentoData = new Date(fatura.vencimento);
                          const dia = vencimentoData.getUTCDate().toString().padStart(2, '0');
                          const mes = vencimentoData.toLocaleString('pt-BR', { month: 'short' }).toUpperCase();
                          const ano = vencimentoData.getUTCFullYear();

                          const hoje = new Date();
                          const status = vencimentoData < hoje ?  
                            <>
                              <IconInfoError />
                              <p>Atrasado</p>
                            </> : 
                            
                            <>
                              <IconInfo />
                              <p>Disponível para <br/> pagamento</p>
                            </>

                          return (
                            <div className='fatura-box' key={fatura.id}>
                              <div className='venc-box'>
                                <p>VENC.</p>
                                <h3>{dia}</h3>
                                <p>{mes}/{ano}</p>
                              </div>

                              <div className='desc-box'>
                                <p>SERVIÇOS</p>
                                <b>Sistema Nexcar <br/>Plano {fatura.plano}</b>
                              </div>

                              <div className='info-box'>
                                {
                                  fatura.pagamento_status === "pending" ?
                                    <>
                                    <IconInfoWaning />
                                    <p>Aguardando <br/> Pagamento</p>
                                    </>
                                  :

                                  <>{status}</>
                                }
                              </div>

                              <div className='valor-box'>
                                <h3>{formataDinheiro(fatura.valor)}</h3>
                              </div>

                              {
                                fatura.payment_method_id === "bolbradesco" ?

                                <div style={{ width: '129px'}}>
                                  <ButtonSecondary icon={<IconBoleto />} txt="Boleto" onClick={() => { window.open(fatura.external_resource_url, "_blank"); }} />
                                </div>

                                :

                                <div style={{ width: '129px'}}>
                                  <ButtonDefault icon={<IconMoney />} txt="Pagar" onClick={() => handlePagarFatura(fatura)} />
                                </div>
                              }
                            </div>
                          );
                        })
                      ) : (
                        <div className='no-fatura-disponivel'>
                          <IconNotFound />
                          <h3>Nenhuma Fatura Disponível</h3>
                          <p>Atualmente, você não possui nenhuma fatura <br/>disponível para pagamento.</p>
                        </div>
                      )}
                      </div>
                    </TabPanel>

                    <TabPanel value="2" style={{ width: "100%" }}>
                      <div style={{ width: "100%", display: 'flex', flexDirection: 'column', gap: '16px' }}>
                      {faturas.length > 0 ? (
                        faturas.map(fatura => {
                          const vencimentoData = new Date(fatura.vencimento);
                          const dia = vencimentoData.getUTCDate().toString().padStart(2, '0');
                          const mes = vencimentoData.toLocaleString('pt-BR', { month: 'short' }).toUpperCase();
                          const ano = vencimentoData.getUTCFullYear();

                          return (
                            <div className='fatura-box' key={fatura.id}>
                              <div className='venc-box'>
                                <p>VENC.</p>
                                <h3>{dia}</h3>
                                <p>{mes}/{ano}</p>
                              </div>

                              <div className='desc-box'>
                                <p>SERVIÇOS</p>
                                <b>Sistema Nexcar <br/>Plano {fatura.plano}</b>
                              </div>

                              <div className='info-box'>
                                <IconInfo />
                                <p>Paga</p>
                              </div>

                              <div className='valor-box'>
                                <h3>{formataDinheiro(fatura.valor)}</h3>
                              </div>

                              {/* <div style={{ width: '99px'}}>
                                <ButtonDefault txt="Pagar" onClick={() => handlePagarFatura(fatura)} />
                              </div> */}
                            </div>
                          );
                        })
                      ) : (
                        <div className='no-fatura-disponivel'>
                          <IconNotFound />
                          <h3>Sem Faturas Pagas</h3>
                          <p>No momento, não há faturas pagas <br/> disponíveis para visualização.</p>
                        </div>
                      )}
                      </div>
                    </TabPanel>
                  </TabContext>

                </div>

            </div>
          </div>
        </div>
        <Footer/>
      </div>
    </>
    
  );
}