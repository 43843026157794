import React, {useState, useRef, useEffect } from 'react';
import './style.css';

import api from '../../../services/api';

import { useFormik } from 'formik';
import * as yup from 'yup';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Swal from 'sweetalert2';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';

import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem'

import ButtonDefault from '../../../componentes/ButtonDefault';
import ButtonOutlineSecondary from '../../../componentes/ButtonOutlineSecondary';
import ButtonSquadPrimaryForInput from '../../../componentes/ButtonSquadPrimaryForInput';

import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { TelMask, mtel } from '../../../vendor/mascaras';

export default function ModalEnvioVeiculoWhatsApp({ activeChat, visible, change }) {

  let IconPlus = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34">
        <path id="plus" d="M15.671,22.975V18.326H11.023a1.328,1.328,0,1,1,0-2.656h4.648V11.022a1.328,1.328,0,0,1,2.656,0V15.67h4.648a1.328,1.328,0,0,1,0,2.656H18.328v4.648a1.328,1.328,0,0,1-2.656,0Zm13.349-18A17,17,0,0,0,4.979,29.02,17.006,17.006,0,0,0,25.8,31.55a1.328,1.328,0,1,0-1.376-2.272,14.47,14.47,0,1,1,4.492-4.317,1.328,1.328,0,1,0,2.208,1.476,16.959,16.959,0,0,0-2.1-21.458Zm0,0" transform="translate(0.001 0)" fill="#fff"/>
    </svg>
  );

  let IconInfo = props =>(
    <svg id="information-button" xmlns="http://www.w3.org/2000/svg" width="23" viewBox="0 0 27.917 27.917">
        <path id="Caminho_118" data-name="Caminho 118" d="M13.958,0A13.959,13.959,0,1,0,27.917,13.958,13.959,13.959,0,0,0,13.958,0Zm2.906,21.633q-1.078.425-1.719.648a4.536,4.536,0,0,1-1.491.223,2.972,2.972,0,0,1-2.029-.637,2.055,2.055,0,0,1-.722-1.615,5.868,5.868,0,0,1,.053-.779c.037-.265.095-.562.174-.9l.9-3.176c.079-.3.148-.594.2-.864a3.83,3.83,0,0,0,.08-.748,1.152,1.152,0,0,0-.251-.847A1.423,1.423,0,0,0,11.1,12.7a2.521,2.521,0,0,0-.715.106c-.242.074-.453.142-.625.208L10,12.039q.883-.36,1.69-.616a4.992,4.992,0,0,1,1.524-.258,2.917,2.917,0,0,1,2,.626,2.081,2.081,0,0,1,.7,1.626q0,.207-.048.729a4.879,4.879,0,0,1-.18.958l-.895,3.167c-.073.254-.138.545-.2.87a4.6,4.6,0,0,0-.086.74,1.062,1.062,0,0,0,.282.86,1.581,1.581,0,0,0,.977.229,2.835,2.835,0,0,0,.74-.115,4.206,4.206,0,0,0,.6-.2ZM16.706,8.779A2.135,2.135,0,0,1,15.2,9.36a2.157,2.157,0,0,1-1.513-.581,1.856,1.856,0,0,1-.63-1.41,1.875,1.875,0,0,1,.63-1.413A2.142,2.142,0,0,1,15.2,5.368a2.118,2.118,0,0,1,1.507.587,1.9,1.9,0,0,1,0,2.823Z" fill="#fff"/>
    </svg>
  );

  const inputNumero = useRef(null);
  const [loaderPage, setloaderPage] = useState(false);
  const [contaswhatsapp, setcontaswhatsapp] = useState([]);
  const [veiculos, setveiculos] = useState([]);



  const getContasWhatsApp = async()=>{

    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let response = await api.get(`listagemContasWhatsApp`, {
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    });
    
    let arrayContas = [];
    
    for await (let conta of response.data) {
        let dados = {
            deviceid: conta.device_id,
            nome: conta.nome
        }    
        
        arrayContas.push(dados);
    }
    
    if(!arrayContas[0]){
      return;
    }
  
    setcontaswhatsapp(arrayContas);
    formik.setFieldValue('contawhatsapp', JSON.stringify(arrayContas[0]));

  }

  const getVeiculos = async(veiculoId)=>{

    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let response = await api.get(`listagemCompras?termo=&status=1&consignado=&notInCompra=&finalPlaca=`, {
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    });

    let arrayVeiculos = [];
    
    for await (let data of response.data) {
        let dados = {
            id: data.produtoId,
            compraId: data.id,
            codigoCompra: data.codigo,
            nome: `${data.placa} - ${data.marca} ${data.modelo}`,
            logoMarca: data.marcaLogo,
            precoVenda: data.precoVenda,
            precoFipe: data.precoFipe,
        }    
        
        arrayVeiculos.push(dados);
    }

    setveiculos(arrayVeiculos);

    //populando veiculo no select
    let veiculo = arrayVeiculos.find(x => x.id === veiculoId);
    veiculo && formik.setFieldValue('veiculo', JSON.stringify(veiculo));

  }


  useEffect(async()=>{
    getContasWhatsApp();

    // eslint-disable-next-line react-hooks/exhaustive-deps   
  },[]);

  useEffect(async()=>{
    if(visible){
      await getVeiculos();
    }

  },[visible]);

  const validationSchema = yup.object({

    contawhatsapp: yup
      .string('Selecione a conta do WhatsApp')
      .required('Conta do WhatsApp Obrigatória')
      .test({
        message: () => "Selecione a conta do WhatsApp",
        test: async (values) => {
          if (values) { 
            if (values === 'null') { return false; } else{ return true; }
          }
        },
      }),

      veiculo: yup
      .string('Selecione um veículo')
      .required('Veículo Obrigatória')
      .test({
        message: () => "Selecione um veículo",
        test: async (values) => {
          if (values) { 
            if (values === 'null') { return false; } else{ return true; }
          }
        },
      }),
  });

  const formik = useFormik({
    initialValues: {
      contawhatsapp: 'null',
      veiculo: 'null',
      enviaropcionais: false,
      enviarcaracteristicas: false,
      somenteumafoto: true,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setloaderPage(true);
  
      let auth = localStorage.getItem("auth");
      auth = JSON.parse(auth);

      let contawhatsapp = JSON.parse(values.contawhatsapp);
      let veiculo = JSON.parse(values.veiculo);

      await api.post('sendVeiculoWhatsApp', {
          "deviceId": contawhatsapp.deviceid,
          "compraId": veiculo.compraId,
          "numero": activeChat.id.user,
          "enviaropcionais": values.enviaropcionais,
          "enviarcaracteristicas": values.enviarcaracteristicas,
          "somenteumafoto": values.somenteumafoto,
      }, 
      {
          headers: {
              "Content-type": "application/json",
              "Authorization": `Bearer ${auth.token}`
          }
      });
    
      // formik.resetForm();
      change(false);
      setloaderPage(false);

      Swal.fire(
        'Envio concluído!',
        'Veículo enviado com sucesso!',
        'success'
      )
      
    }
  });


  const handleChangeContawhatsapp = async(event, value, reason, details)=>{
    formik.setFieldValue('contawhatsapp', JSON.stringify(value));
  }

  const handleChangeVeiculo = async(event, value, reason, details)=>{
    formik.setFieldValue('veiculo', JSON.stringify(value));
  }


  return(
    <>
    <Backdrop
      sx={{ color: '#fff', zIndex: '2000' }}
      open={loaderPage}
      // onClick={handleClose}
    >
      <CircularProgress color="inherit" />
    </Backdrop>

    <Dialog open={visible} maxWidth='sm' fullWidth={true} onClose={()=>{ change(false) }}>
      <form method='post' onSubmit={formik.handleSubmit}>
        <DialogTitle sx={{ m: 0, p: 2 }}>
            Envio estoque WhatsApp
            <IconButton
              aria-label="close"
              onClick={()=>{ change(false) }}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText></DialogContentText>

          <Grid container spacing={3} >

            <Grid item xs={12} md={12} lg={12}>
                <FormControl sx={{ m: 0, width: '100%' }} >
                <Autocomplete
                    // disablePortal
                    id="combo-box-contawhats"
                    options={contaswhatsapp}
                    value={JSON.parse(formik.values.contawhatsapp)}
                    getOptionLabel={(option) => option.nome}
                    isOptionEqualToValue={(option, value) => option.deviceid === value.deviceid}
                    onChange={handleChangeContawhatsapp}
                    noOptionsText="Nenhuma conta encontrada."
                    sx={{ width: '100%' }}
                    // disabled={!permissoesdoUsuario[3]?.update}
                    renderInput={(params) => <TextField {...params} label="Conta de WhatsApp"  error={formik.touched.contawhatsapp && Boolean(formik.errors.contawhatsapp)}/>}
                />
                <FormHelperText error>{formik.touched.contawhatsapp && formik.errors.contawhatsapp}</FormHelperText>
                </FormControl>
            </Grid>

            <Grid item xs={12} md={12} >
              <FormControl sx={{ m: 0, width: '100%' }} >
                <Autocomplete
                    className='input'
                    // disablePortal
                    id="combo-box-veiculo"
                    options={veiculos}
                    value={JSON.parse(formik.values.veiculo)}
                    getOptionLabel={(option) => option.nome}
                    renderOption={(props, option) => (
                      <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                        <img
                          loading="lazy"
                          width="35"
                          src={option.logoMarca}
                          alt=""
                        />
                        {option.nome}
                      </Box>
                    )}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    onChange={handleChangeVeiculo}
                    noOptionsText="Nenhum veículo encontrado."
                    sx={{ 
                      width: '100%', 
                      // Ajustes para os ícones
                      '& .MuiAutocomplete-popupIndicator': {
                        color: '#fff', // Ícone de abrir lista (branco)
                      },
                      '& .MuiAutocomplete-clearIndicator': {
                        color: '#fff', // Ícone de limpar (branco)
                      },
                      // Fundo do campo de entrada (Autocomplete)
                      '& .MuiInputBase-root': {
                        backgroundColor: '#383838', // Fundo para o campo de entrada
                        color: '#fff', // Cor do texto (branco)
                      },
                      // Borda do campo de entrada
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#606060', // Cor da borda padrão
                      },
                      '& .MuiOutlinedInput-root': {
                        '&:hover fieldset': {
                          borderColor: '#fff', // Borda branca ao passar o mouse
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#fff', // Borda branca ao focar
                        },
                        backgroundColor: '#383838', // Fundo do campo de entrada
                      },
                      '& .MuiOutlinedInput-input': {
                        backgroundColor: '#383838', // Fundo do input
                        color: '#fff', // Cor do texto (branco)
                      },
                      // Fundo do FormControl (para fullWidth)
                      '& .MuiFormControl-fullWidth': {
                        backgroundColor: '#383838', // Fundo personalizado para o FormControl
                      },
                      // Lista de opções (Autocomplete)
                      '& .MuiAutocomplete-listbox': {
                        backgroundColor: '#383838', // Fundo da lista de opções
                        color: '#fff', // Texto branco
                      },
                      // Opções da lista de Autocomplete
                      '& .MuiAutocomplete-option': {
                        '&[aria-selected="true"]': {
                          backgroundColor: '#505050', // Cor de fundo para a opção selecionada
                        },
                        '&[data-focus="true"]': {
                          backgroundColor: '#505050', // Cor de fundo ao focar a opção
                        },
                      },
                    }}
                    
                    renderInput={(params) => <TextField {...params} label="Veículo"  error={formik.touched.veiculo && Boolean(formik.errors.veiculo)}/>}
                />
                <FormHelperText error>{formik.touched.veiculo && formik.errors.veiculo}</FormHelperText>
              </FormControl>
            </Grid>


            <Grid item xs={12} md={6}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formik.values.enviaropcionais}
                      onChange={(e) => formik.setFieldValue('enviaropcionais', e.target.checked)}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  }
                  label="Enviar opcionais"
                />
              </FormGroup>
            </Grid>

            <Grid item xs={12} md={6}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formik.values.enviarcaracteristicas}
                      onChange={(e) => formik.setFieldValue('enviarcaracteristicas', e.target.checked)}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  }
                  label="Enviar caracteristicas"
                />
              </FormGroup>
            </Grid>

            <Grid item xs={12} md={6}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formik.values.somenteumafoto}
                      onChange={(e) => formik.setFieldValue('somenteumafoto', e.target.checked)}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  }
                  label="Enviar somente uma foto"
                />
              </FormGroup>
            </Grid>
        
          </Grid>

        </DialogContent>
        <DialogActions>
          <Grid container direction="row" justifyContent="flex-end" spacing={2} style={{marginRight: '15px'}}>

          <Grid item xs={4} md={3}>
            <ButtonOutlineSecondary type="button" onClick={()=>{ change(false) }} icon="" txt="Cancelar" />
          </Grid>
          
          <Grid item xs={4} md={3}>
            <ButtonDefault type="submit" icon="" txt="Enviar" />
          </Grid>

          </Grid>
        </DialogActions>
        </form>
    </Dialog>
    </>
  );

}