import React, { useState, useEffect } from 'react';
import moment from 'moment';
import api from '../../services/api';
import './style.css';

import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';

const ImgComponent = ({ data, tempo }) => {
    const [media, setMedia] = useState({
        base64: data.body,
        mimetype: data.mimetype || 'image/jpeg'
    });

    const getMediaByMessage = async (messageId) => {
        let auth = localStorage.getItem("auth");
        auth = JSON.parse(auth);

        let response = await api.post('whatsAppGetMediaByMessage', {
            "messageId": messageId
        }, {
            headers: {
                "Content-type": "application/json",
                "Authorization": `Bearer ${auth.token}`
            }
        });

        setMedia(response.data);
    };

    useEffect(() => {
        if (data.body) {
            getMediaByMessage(data.id);
        }
    }, [data]);

    return (
        <>
            {media.base64 && media.mimetype && (
                <div className='element-anexo-msg'>
                    <aside
                        style={{
                            backgroundImage: `url(data:${media.mimetype};base64,${media.base64})`,
                            transition: 'background-image 0.5s ease-in-out'
                        }}
                        data-fancybox="gallery"
                        href={`data:${media.mimetype};base64,${media.base64}`}
                        data-caption={data?.caption}
                    />
                    {
                        data?.caption &&
                        <div className='caption-img'>
                            <p>{data.caption}</p>
                        </div>
                    }

                    <span>
                        <Tooltip title={`${moment(data.data).format('DD/MM/YYYY')} às ${moment(data.data).format('HH:mm')}`}>
                            <Button>{tempo}</Button>
                        </Tooltip>
                    </span>
                </div>
            )}
        </>
    );
};

export default ImgComponent;
