import React, {useState, useEffect, useRef} from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import './style.css';

import api from '../../services/api';

import { useParams, useHistory, Link } from "react-router-dom";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@material-ui/lab/TabContext';
import Tabs from '@mui/material/Tabs';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import Grid from '@mui/material/Grid';

import Footer from '../../componentes/Footer';
import TabCompras from './tabCompras';
import TabComprasAnexos from './tabComprasAnexos';
import TabVendasAnexos from './tabVendasAnexos';
import TabVendas from './tabVendas';
import AutorizacoesServicos from './tabAutorizacoesServicos';
import LiberacoesVeiculo from './tabLiberacoesVeiculo';
import Despesas from './tabDespesas';
import TabCadastroVeiculo from './tabCadastroVeiculo';
import TabStatusMsg from './tabStatusMsg';

export default function EstoqueDetalhes() {

  let { codigoCompra, codigoVenda } = useParams();
  let history = useHistory();

  const [tabPage, settabPage] = useState("1");
  const [vendas, setvendas] = useState([]);
  const [compras, setcompras] = useState([]);

  const [handleNewTab, sethandleNewTab] = useState(false);
  
  const detalhesCompraVenda = async()=>{

    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    await api.post(`detalhesCompra`, {
      "codigo": codigoCompra
    },{
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    }).then( async response => {

      if(codigoVenda){

        let detalhesVenda = await api.post(`detalhesVenda`, {
          "codigo": codigoVenda
        },{
          headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${auth.token}`
          }
        })

        setvendas(detalhesVenda.data);
        handleNewTab ? settabPage(handleNewTab) : settabPage('3')
      }

            
      let data = response.data;
      setcompras(data);

    }).catch((error) => {
        history.push("/estoque");
    });

  }

  useEffect(()=>{
    detalhesCompraVenda();
    // eslint-disable-next-line react-hooks/exhaustive-deps   
  },[]);

  const handleChangeTabPage = (event, newValue) => {
    settabPage(newValue); 
  };

  

  function displayStatusVenda(status){
    switch (status) {
      case 1:
        return(<div className='box-display-status-venda-compra pendente'>Pendente</div>)
        break;

      case 2:
        return(<div className='box-display-status-venda-compra comunicadoVenda'>Comunicado venda</div>)
        break;

      case 3:
        return(<div className='box-display-status-venda-compra finalizado'>Finalizado</div>)
        break;

      case 4:
        return(<div className='box-display-status-venda-compra comunicadoVendaComObs'>Comunicado venda c/ OBS</div>)
        break;

      case 5:
        return(<div className='box-display-status-venda-compra finalizadoComObs'>Finalizado c/ OBS</div>)
        break;
    }  
  }

  function displayStatusCompra(status){
    switch (status) {
      case 0:
        return(<div className='box-display-status-venda-compra inativo'>Inativo</div>)
        break;

      case 1:
        return(<div className='box-display-status-venda-compra ativo'>Ativo</div>)
        break;
    }  
  }

  return (
    <>
      <HelmetProvider>
        <Helmet>
            <title>Nexcar - Estoque detalhes</title>
        </Helmet>
      </HelmetProvider>

      <div>
      <div className='container-page'> 
        <div className='container'>

          <Box style={{ marginTop: '20px' }}>
            <Grid container spacing={2} >
              
              <Grid item xs={12} md={12}>
                <div className='box-content-detalhes-estoque'>
                  
                  <div className='header-box-content-detalhes-estoque'>
                    <h3 className='title' style={{ cursor: 'pointer' }} ><Link to={`/cadastro/veiculo/${compras?.codigoProduto}`} style={{ color: '#4A4A4A' }}>{compras?.placa}</Link></h3>

                    {
                      vendas?.id ? displayStatusVenda(vendas?.status) : displayStatusCompra(compras?.status)
                    }

                  </div>
                  <Grid container item spacing={0} xs={12} md={12} direction="row">
                    <Grid item xs={12} md={4}>
                      <div className='box-left-info-car-detalhes-estoque'>
                        <div className='box-foto-marca-detalhes-estoque' style={{ backgroundImage: `url(${compras?.marcaLogo})` }}></div>
                        
                        <aside>
                          <p>{compras?.marca}</p>
                          <p>{compras?.modelo}</p>
                        </aside>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <div className='box-right-info-car-detalhes-estoque'>
                            <aside>
                              <p>{compras?.ano} / {compras?.anoFabricacao}</p>
                              <p>{compras?.cor && compras?.cor.toUpperCase()}</p>
                            </aside>
                            
                            <article>
                              <p><b>CÓDIGO FIPE:</b> {compras?.fipeCodigo}</p>
                            </article>
                        </div>
                    </Grid>
                  </Grid>
                  

                </div>
              </Grid>

              <Grid item xs={12} md={12}>
                <TabContext value={tabPage}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}> 
                    <Tabs value={tabPage} onChange={handleChangeTabPage} aria-label="estoque-tabs" textColor="primary" indicatorColor="primary" variant="scrollable" scrollButtons allowScrollButtonsMobile>
                      <Tab label="Cadastro" value="0" />
                      <Tab label="Compra" value="1" />
                      <Tab label="Anexos compra" value="2" />
                      <Tab label="Venda" value="3" disabled={ vendas?.id ? false : true } />
                      <Tab label="Anexos venda" value="4" disabled={ vendas?.id ? false : true } />
                      <Tab label="Status" value="5"/>
                      <Tab label="Autorizações de serviços" value="6" />
                      <Tab label="Liberações" value="7" />
                      <Tab label="Despesas" value="8" />
                      {/* <Tab label="Protocolo de entrega" value="9" disabled={ vendas?.id ? false : true }/> */}
                    </Tabs>
                  </Box>
                  <TabPanel value="0">
                    <TabCadastroVeiculo dadosCompra={compras}/>
                  </TabPanel>

                  <TabPanel value="1">
                    <TabCompras dadosCompra={compras} dadosVenda={vendas} />
                  </TabPanel>

                  <TabPanel value="2">
                    <TabComprasAnexos dadosCompra={compras} dadosVenda={vendas}/>
                  </TabPanel>

                  <TabPanel value="3">
                    <TabVendas dadosCompra={compras} dadosVenda={vendas} refreshDetalhesCompraVenda={detalhesCompraVenda}/>
                  </TabPanel>

                  <TabPanel value="4">
                    <TabVendasAnexos dadosVenda={vendas}/>
                  </TabPanel>

                  <TabPanel value="5">
                    <TabStatusMsg dadosCompra={compras} dadosVenda={vendas} refreshDetalhesCompraVenda={detalhesCompraVenda} handleNewTab={sethandleNewTab} />
                  </TabPanel>

                  <TabPanel value="6">
                    <AutorizacoesServicos dadosCompra={compras}/>
                  </TabPanel>

                  <TabPanel value="7">
                    <LiberacoesVeiculo dadosCompra={compras}/>
                  </TabPanel>

                  <TabPanel value="8">
                    <Despesas dadosCompra={compras} />
                  </TabPanel>

                  <TabPanel value="9">
                    
                  </TabPanel>
                </TabContext>
              </Grid>

            </Grid>
          </Box>




        </div>
      </div> 
      <Footer/>
      </div>
    </>
    
  );
}