import React, {useState, useEffect} from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import api from '../../services/api';
import Footer from '../../componentes/Footer';
import './style.css';

export default function EmailInbox() {

  const [conta, setconta] = useState([]);


  const getContasEmails = async()=>{

    //GET DADOS USER
    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let response = await api.post('getContasEmails', {}, 
    {
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    });

    setconta(response.data);
  }


  useEffect(()=>{
    getContasEmails();

    // eslint-disable-next-line react-hooks/exhaustive-deps   
  },[]);


  function Iframe(props) {
    return (<div dangerouslySetInnerHTML={ {__html:  props.iframe?props.iframe:""}} />);
  }

  return (
    <>
      <HelmetProvider>
        <Helmet>
            <title>Nexcar - E-mail inbox</title>
        </Helmet>
      </HelmetProvider>

      <div className='container-page-webmail'>
        <div className='container-page' style={{ marginBottom: 0 }}>

            {/* <Iframe iframe={`<iframe width="100%" height="800px" src="https://webmail.nexcar.digital/login-to-account.php?e=dmljdG9yQHN0c3cuY29tLmJy&s=SXJvbnhAMTIxNQ==" style="border: none; margin: none"></iframe>`} /> */}
            { conta.length > 0 && 
              <Iframe iframe={`<iframe class="iframe-webmail" src="https://webmail.nexcar.digital/login-to-account.php?e=${conta[0]?.email}&s=${conta[0]?.senha}" style="border: none; margin: none"></iframe>`} />
            }

        </div>
        <Footer/>
      </div>
    </>
    
  );
}