import React, { useState, useEffect, useRef } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import "./style.css";
import moment from 'moment';

import api from "../../../services/api";

import { useHistory } from "react-router-dom";
import { DataGrid, ptBR, GridOverlay } from "@mui/x-data-grid";
import Swal from "sweetalert2";

import ButtonSquadPrimary from "../../../componentes/ButtonSquadPrimary";
import ButtonSquadSecondary from "../../../componentes/ButtonSquadSecondary";
import ButtonSquadDanger from "../../../componentes/ButtonSquadDanger";
import InputSearchActions from "../../../componentes/InputSearchActions";

import Footer from "../../../componentes/Footer";
import Submenu from "../../../componentes/SubMenu";

import ModalNovaLista from "./modals/modalNovaLista";
import ModalEditLista from "./modals/modalEditLista";
import ButtonCirclePrimary from "../../../componentes/ButtonCirclePrimary";

export default function ListasTransmissoes() {
  let IconPessoa = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 22 26">
      <g
        id="user_account_people_man"
        data-name="user, account, people, man"
        transform="translate(-5 -3)"
      >
        <path
          id="Caminho_44"
          data-name="Caminho 44"
          d="M23.737,16.181A1,1,0,1,0,22.331,17.6,8.938,8.938,0,0,1,25,24c0,1.223-3.506,3-9,3s-9-1.778-9-3a8.939,8.939,0,0,1,2.635-6.363,1,1,0,1,0-1.414-1.414A10.927,10.927,0,0,0,5,24c0,3.248,5.667,5,11,5s11-1.752,11-5a10.92,10.92,0,0,0-3.263-7.819Z"
          fill="#4a4a4a"
        />
        <path
          id="Caminho_45"
          data-name="Caminho 45"
          d="M16,17a7,7,0,1,0-7-7A7,7,0,0,0,16,17ZM16,5a5,5,0,1,1-5,5,5,5,0,0,1,5-5Z"
          fill="#4a4a4a"
        />
      </g>
    </svg>
  );

  let IconCarro = (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      viewBox="0 0 47.701 17.828"
    >
      <g id="sedan" transform="translate(0 -160.323)">
        <path
          id="Caminho_46"
          data-name="Caminho 46"
          d="M91.224,293.383a.7.7,0,0,0,.494-.2l1.056-1.056a.7.7,0,0,0-.988-.988L90.73,292.19a.7.7,0,0,0,.494,1.193Z"
          transform="translate(-82.091 -118.438)"
          fill="#4a4a4a"
        />
        <path
          id="Caminho_47"
          data-name="Caminho 47"
          d="M404.416,293.177a.7.7,0,1,0,.988-.988l-1.056-1.056a.7.7,0,1,0-.988.988Z"
          transform="translate(-365.595 -118.437)"
          fill="#4a4a4a"
        />
        <path
          id="Caminho_48"
          data-name="Caminho 48"
          d="M45.594,167.577c-3.947-1.833-9.883-2.008-11.082-2.025l-5.661-3.6a10.391,10.391,0,0,0-5.588-1.627H16.61a11.211,11.211,0,0,0-4.988,1.178l-3.544,1.772A10.477,10.477,0,0,1,4.683,164.3l-2.762.345A2.2,2.2,0,0,0,0,166.821v4.46a2.952,2.952,0,0,0,2.132,2.826l3.3.943a4.431,4.431,0,0,0,8.078.86H34.937a4.437,4.437,0,1,0-.51-1.4H14.021a4.432,4.432,0,1,0-8.789-.973l-2.717-.776A1.548,1.548,0,0,1,1.4,171.281v-2.092h.795a.7.7,0,1,0,0-1.4H1.4v-.97a.8.8,0,0,1,.7-.789l2.762-.345A11.872,11.872,0,0,0,8.7,164.523l.319-.159,1.293,1.287a4.4,4.4,0,0,0,3.135,1.3h1.441a.7.7,0,1,0,0-1.4H13.448a3.074,3.074,0,0,1-.362-.022l.772-3.41a9.794,9.794,0,0,1,2.752-.4h3.554v3.83H17.876a.7.7,0,1,0,0,1.4h16.43c.066,0,6.668.024,10.7,1.9a2.2,2.2,0,0,1,.627.44h-.871a.7.7,0,1,0,0,1.4h1.529c.008.076.013.152.013.229v2.479c0,.692-.3,1.122-.795,1.122a.7.7,0,1,0,0,1.4,2.068,2.068,0,0,0,1.583-.717,2.7,2.7,0,0,0,.609-1.8v-2.479A3.678,3.678,0,0,0,45.594,167.577Zm-6.807,3.105a3.035,3.035,0,1,1-3.035,3.035A3.039,3.039,0,0,1,38.787,170.682Zm-29.127,0a3.035,3.035,0,1,1-3.035,3.035A3.039,3.039,0,0,1,9.661,170.682Zm2.1-5.641a3.067,3.067,0,0,1-.461-.38l-.959-.959,1.9-.952.039-.019Zm9.8-3.321h1.7a9,9,0,0,1,4.837,1.409l3.806,2.422H21.562Z"
          fill="#4a4a4a"
        />
        <path
          id="Caminho_49"
          data-name="Caminho 49"
          d="M169.038,280.564a.7.7,0,1,0,0,1.4h15.683a.7.7,0,0,0,0-1.4Z"
          transform="translate(-152.655 -109.039)"
          fill="#4a4a4a"
        />
      </g>
    </svg>
  );

  let IconContrato = (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      viewBox="0 0 27.107 27.107"
    >
      <g id="contract" transform="translate(0 -0.5)">
        <path
          id="Caminho_50"
          data-name="Caminho 50"
          d="M20.743,27.607H.868A.867.867,0,0,1,0,26.74V1.367A.867.867,0,0,1,.868.5H20.743a.867.867,0,0,1,.867.867v9.059a.867.867,0,1,1-1.735,0V2.234H1.734V25.873H19.875V21.82a.867.867,0,1,1,1.735,0v4.92a.867.867,0,0,1-.867.867Zm0,0"
          fill="#4a4a4a"
        />
        <path
          id="Caminho_51"
          data-name="Caminho 51"
          d="M215.468,127.775h-.153a.868.868,0,0,1-.849-.85c-.009-.443,0-2.706,1.009-3.718L226.4,112.276a.894.894,0,0,1,1.227,0l2.333,2.333a.867.867,0,1,1-1.227,1.227l-1.719-1.72L216.7,124.432a3.154,3.154,0,0,0-.458,1.564,3.141,3.141,0,0,0,1.565-.458l9.231-9.233a.867.867,0,0,1,1.478.541c.425,5.1-2.163,6.576-3.553,7.371a3.948,3.948,0,0,0-.684.443,2.034,2.034,0,0,0-.546.9.861.861,0,0,1-.973.733.877.877,0,0,1-.742-.987,3.532,3.532,0,0,1,1-1.834,4.548,4.548,0,0,1,1.086-.761,4.487,4.487,0,0,0,2.658-3.675l-7.726,7.728c-.927.926-2.9,1.008-3.568,1.008Zm0,0"
          transform="translate(-203.11 -105.627)"
          fill="#4a4a4a"
        />
        <path
          id="Caminho_52"
          data-name="Caminho 52"
          d="M67.856,302.92a2.018,2.018,0,0,1-1.388-.532,2.906,2.906,0,0,1-1.69.532,3.527,3.527,0,0,1-1.858-.473,4.2,4.2,0,0,1-1.881.473.867.867,0,1,1,0-1.735,2.285,2.285,0,0,0,.738-.131,3.175,3.175,0,0,1-.116-.359,4.6,4.6,0,0,1,1.682-4.7,1.835,1.835,0,0,1,2.318.379,2.594,2.594,0,0,1,.205,2.9,6.557,6.557,0,0,1-1.27,1.906c.057,0,.118.006.182.006.533,0,1.018-.373,1.018-.782a.867.867,0,1,1,1.735,0,1.328,1.328,0,0,0,.113.687l1-.86a.868.868,0,1,1,1.133,1.314l-1.353,1.165A.87.87,0,0,1,67.856,302.92Zm-3.584-5.444c-.055,0-.294.135-.544.556a3.326,3.326,0,0,0-.435,2,4.839,4.839,0,0,0,.975-1.439c.263-.619.2-.961.147-1.015-.146-.151-.142-.107-.143-.107Zm0,0"
          transform="translate(-56.986 -279.609)"
          fill="#4a4a4a"
        />
        <path
          id="Caminho_53"
          data-name="Caminho 53"
          d="M84.287,80.145H71.938a.867.867,0,1,1,0-1.735H84.287a.867.867,0,1,1,0,1.735Zm0,0"
          transform="translate(-67.307 -73.785)"
          fill="#4a4a4a"
        />
        <path
          id="Caminho_54"
          data-name="Caminho 54"
          d="M84.287,151.852H71.938a.867.867,0,1,1,0-1.735H84.287a.867.867,0,1,1,0,1.735Zm0,0"
          transform="translate(-67.307 -141.696)"
          fill="#4a4a4a"
        />
        <path
          id="Caminho_55"
          data-name="Caminho 55"
          d="M78.516,223.543H71.938a.867.867,0,1,1,0-1.735h6.579a.867.867,0,1,1,0,1.735Zm0,0"
          transform="translate(-67.307 -209.591)"
          fill="#4a4a4a"
        />
        <path
          id="Caminho_56"
          data-name="Caminho 56"
          d="M248.739,302.848a.866.866,0,0,1-.614-.254l-.982-.982a.867.867,0,1,1,1.227-1.227l.982.982a.867.867,0,0,1-.613,1.481Zm0,0"
          transform="translate(-233.817 -283.767)"
          fill="#4a4a4a"
        />
      </g>
    </svg>
  );

  let IconPlusBtn = (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 34 34"
    >
      <path
        id="plus"
        d="M15.671,22.975V18.326H11.023a1.328,1.328,0,1,1,0-2.656h4.648V11.022a1.328,1.328,0,0,1,2.656,0V15.67h4.648a1.328,1.328,0,0,1,0,2.656H18.328v4.648a1.328,1.328,0,0,1-2.656,0Zm13.349-18A17,17,0,0,0,4.979,29.02,17.006,17.006,0,0,0,25.8,31.55a1.328,1.328,0,1,0-1.376-2.272,14.47,14.47,0,1,1,4.492-4.317,1.328,1.328,0,1,0,2.208,1.476,16.959,16.959,0,0,0-2.1-21.458Zm0,0"
        transform="translate(0.001 0)"
        fill="#fff"
      />
    </svg>
  );

  let IconPDFbtn = (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="27.758"
      viewBox="0 0 24.93 27.758"
    >
      <g id="pdf" transform="translate(-26.077)">
        <g
          id="Grupo_19"
          data-name="Grupo 19"
          transform="translate(28.387 25.607)"
        >
          <g id="Grupo_18" data-name="Grupo 18">
            <path
              id="Caminho_62"
              data-name="Caminho 62"
              d="M68.677,472.334a3.073,3.073,0,0,0,2.914,2.15H86.074a3.072,3.072,0,0,0,2.914-2.15Z"
              transform="translate(-68.677 -472.334)"
              fill="#fff"
            />
          </g>
        </g>
        <g
          id="Grupo_21"
          data-name="Grupo 21"
          transform="translate(43.912 0.508)"
        >
          <g id="Grupo_20" data-name="Grupo 20">
            <path
              id="Caminho_63"
              data-name="Caminho 63"
              d="M359.085,13.324l-3.563-3.563a3.106,3.106,0,0,0-.475-.389v4.407a.182.182,0,0,0,.182.182h4.345A3.046,3.046,0,0,0,359.085,13.324Z"
              transform="translate(-355.047 -9.372)"
              fill="#fff"
            />
          </g>
        </g>
        <g id="Grupo_23" data-name="Grupo 23" transform="translate(28.238)">
          <g id="Grupo_22" data-name="Grupo 22">
            <path
              id="Caminho_64"
              data-name="Caminho 64"
              d="M86.543,6.724H81.791a1.811,1.811,0,0,1-1.809-1.808V0H69a3.066,3.066,0,0,0-3.063,3.063v8.354H86.543V6.724Z"
              transform="translate(-65.935)"
              fill="#fff"
            />
          </g>
        </g>
        <g
          id="Grupo_25"
          data-name="Grupo 25"
          transform="translate(37.827 16.638)"
        >
          <g id="Grupo_24" data-name="Grupo 24">
            <path
              id="Caminho_65"
              data-name="Caminho 65"
              d="M243.562,306.9h-.744v3.836h.744q.752,0,.752-.836v-2.165Q244.314,306.9,243.562,306.9Z"
              transform="translate(-242.818 -306.896)"
              fill="#fff"
            />
          </g>
        </g>
        <g
          id="Grupo_27"
          data-name="Grupo 27"
          transform="translate(33.072 16.638)"
        >
          <g id="Grupo_26" data-name="Grupo 26">
            <path
              id="Caminho_66"
              data-name="Caminho 66"
              d="M155.928,306.9H155.1v1.763h.827q.752,0,.752-.836v-.092Q156.681,306.9,155.928,306.9Z"
              transform="translate(-155.101 -306.896)"
              fill="#fff"
            />
          </g>
        </g>
        <g
          id="Grupo_29"
          data-name="Grupo 29"
          transform="translate(26.077 13.044)"
        >
          <g id="Grupo_28" data-name="Grupo 28">
            <path
              id="Caminho_67"
              data-name="Caminho 67"
              d="M48.952,240.6H28.132a2.057,2.057,0,0,0-2.055,2.055v6.826a2.057,2.057,0,0,0,2.055,2.055h20.82a2.057,2.057,0,0,0,2.055-2.055V242.66A2.057,2.057,0,0,0,48.952,240.6Zm-13,4.421h0a1.806,1.806,0,0,1-.564,1.467,2.248,2.248,0,0,1-1.492.472h-.827v1.805a.325.325,0,0,1-.192.3,1.063,1.063,0,0,1-.919,0,.324.324,0,0,1-.192-.3v-5.316a.356.356,0,0,1,.134-.276.514.514,0,0,1,.351-.117h1.73a2.121,2.121,0,0,1,1.433.464,1.823,1.823,0,0,1,.539,1.458v.042Zm4.672,2.173a1.859,1.859,0,0,1-.56,1.5,2.232,2.232,0,0,1-1.5.476H37.092a.672.672,0,0,1-.41-.117.338.338,0,0,1-.159-.276v-5.324a.338.338,0,0,1,.159-.276.673.673,0,0,1,.41-.117h1.479a2.232,2.232,0,0,1,1.5.476,1.859,1.859,0,0,1,.56,1.5Zm4.584-3.168a.324.324,0,0,1-.288.167H42.759v1.412h1.262a.333.333,0,0,1,.288.15.615.615,0,0,1,.1.359.6.6,0,0,1-.1.343.33.33,0,0,1-.293.15H42.759v2.156a.325.325,0,0,1-.192.3,1.063,1.063,0,0,1-.919,0,.324.324,0,0,1-.192-.3v-5.307a.331.331,0,0,1,.167-.3.742.742,0,0,1,.4-.1h2.9a.322.322,0,0,1,.293.167.749.749,0,0,1,.1.393A.759.759,0,0,1,45.212,244.032Z"
              transform="translate(-26.077 -240.605)"
              fill="#fff"
            />
          </g>
        </g>
      </g>
    </svg>
  );

  let IconCSVBtn = (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      viewBox="0 0 22.974 27.355"
    >
      <g id="csv" transform="translate(-41)">
        <path
          id="Caminho_199"
          data-name="Caminho 199"
          d="M41,465.107a2.407,2.407,0,0,0,2.4,2.4H59.432a2.407,2.407,0,0,0,2.4-2.4V465H41Z"
          transform="translate(0 -440.156)"
          fill="#fff"
        />
        <path
          id="Caminho_200"
          data-name="Caminho 200"
          d="M55.729,13.927V8.787l-5.942,5.942h5.14A.8.8,0,0,0,55.729,13.927Z"
          transform="translate(-8.318 -8.318)"
          fill="#fff"
        />
        <path
          id="Caminho_201"
          data-name="Caminho 201"
          d="M61.837,2.4a2.407,2.407,0,0,0-2.4-2.4H49.014V5.61a2.407,2.407,0,0,1-2.4,2.4H41v2.939H61.837Z"
          fill="#fff"
        />
        <path
          id="Caminho_202"
          data-name="Caminho 202"
          d="M63.172,235H41v10.685H63.172a.8.8,0,0,0,.8-.8V235.8A.8.8,0,0,0,63.172,235Zm-13.636,7.306a2.35,2.35,0,0,1-1.858.842,2.8,2.8,0,1,1,1.569-5.13.668.668,0,0,1-.748,1.107,1.469,1.469,0,1,0-.821,2.688,1.059,1.059,0,0,0,.729-.255,1.048,1.048,0,0,0,.106-.109.668.668,0,0,1,1.023.858Zm5.093-.633a1.893,1.893,0,0,1-1.927,1.475,2.667,2.667,0,0,1-1.981-.851.668.668,0,1,1,.99-.9,1.348,1.348,0,0,0,.991.413.591.591,0,0,0,.612-.374c.032-.177-.224-.338-.473-.43-.666-.245-1.257-.509-1.282-.52a.671.671,0,0,1-.093-.05,1.489,1.489,0,0,1-.664-1.474,1.633,1.633,0,0,1,1.182-1.321,2.249,2.249,0,0,1,2.449.7.668.668,0,0,1-1.108.735,1.112,1.112,0,0,0-.956-.152.309.309,0,0,0-.245.232.161.161,0,0,0,.044.143c.167.073.635.273,1.135.458A1.732,1.732,0,0,1,54.63,241.673Zm5.437-3.249-1.4,4.2a.766.766,0,0,1-.72.523h-.005a.765.765,0,0,1-.72-.508l-1.463-4.215a.668.668,0,0,1,1.262-.438l.909,2.62L58.8,238a.668.668,0,0,1,1.268.421Z"
          transform="translate(0 -222.445)"
          fill="#fff"
        />
      </g>
    </svg>
  );

  let IconLixeira = (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      viewBox="0 0 115.029 153.224"
    >
      <g id="delete" transform="translate(-63.818 0)">
        <path
          id="Caminho_186"
          data-name="Caminho 186"
          d="M178.847,49.194H107.979l31.736-31.736a4.489,4.489,0,0,0,0-6.349l-8.042-8.042a10.475,10.475,0,0,0-14.813,0l-8.268,8.269L104.358,7.1a11.985,11.985,0,0,0-16.93,0L70.922,23.611a11.985,11.985,0,0,0,0,16.93l4.232,4.233-8.268,8.268a10.475,10.475,0,0,0,0,14.813L74.928,75.9a4.489,4.489,0,0,0,6.348,0L92.654,64.519v69.253a19.453,19.453,0,0,0,19.453,19.453h47.286a19.453,19.453,0,0,0,19.453-19.453ZM81.5,38.424l-4.232-4.232a3,3,0,0,1,0-4.233L93.777,13.453a3,3,0,0,1,4.232,0l4.232,4.233Zm38.764,89.661a4.489,4.489,0,1,1-8.978,0V74.334a4.489,4.489,0,1,1,8.978,0Zm19.973,0a4.489,4.489,0,0,1-8.978,0V74.334a4.489,4.489,0,0,1,8.978,0Zm19.973,0a4.489,4.489,0,1,1-8.978,0V74.334a4.489,4.489,0,1,1,8.978,0Z"
          fill="#fff"
        />
        <path
          id="Caminho_187"
          data-name="Caminho 187"
          d="M309.244,93.149a7.5,7.5,0,0,0-4.977,6.237H347.34V91.925a7.486,7.486,0,0,0-9.882-7.087l-28.121,8.281c-.03.009-.063.021-.093.03Z"
          transform="translate(-168.493 -59.168)"
          fill="#fff"
        />
      </g>
    </svg>
  );

  let IconNoAuth = (props) => (
    <svg
      id="blocked"
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      viewBox="0 0 90.682 90.682"
    >
      <path
        id="Caminho_196"
        data-name="Caminho 196"
        d="M60.455,34.535a8.537,8.537,0,0,0-8.5-8.086H49.119V18.892a18.892,18.892,0,0,0-37.784,0v7.557H8.5A8.514,8.514,0,0,0,0,34.95V67.067a8.514,8.514,0,0,0,8.5,8.5H35.4a32.46,32.46,0,0,1-1.4-9.446A32.072,32.072,0,0,1,60.455,34.535ZM18.892,26.449V18.892a11.335,11.335,0,1,1,22.67,0v7.557Z"
        fill="#4a4a4a"
      />
      <path
        id="Caminho_197"
        data-name="Caminho 197"
        d="M35.56,11a24.56,24.56,0,1,0,24.56,24.56A24.588,24.588,0,0,0,35.56,11Zm0,7.557A16.839,16.839,0,0,1,44.575,21.2L21.2,44.575A16.931,16.931,0,0,1,35.56,18.557Zm0,34.006a16.839,16.839,0,0,1-9.015-2.645L49.921,26.544a16.839,16.839,0,0,1,2.645,9.015,17.029,17.029,0,0,1-17.007,17Z"
        transform="translate(30.563 30.563)"
        fill="#4a4a4a"
      />
    </svg>
  );

  let IconModeloMsg = (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41.691"
      height="40.144"
      viewBox="0 0 41.691 40.144"
    >
      <g id="mensagem" transform="translate(0 0)">
        <path
          id="Caminho_375"
          data-name="Caminho 375"
          d="M1.835,40.144,4.457,29.657A17.014,17.014,0,0,1,0,18.321,17.194,17.194,0,0,1,6.141,5.311,22.123,22.123,0,0,1,20.764,0a22.4,22.4,0,0,1,14.7,5.306,17.131,17.131,0,0,1,6.228,13.015,17.131,17.131,0,0,1-6.228,13.015,22.4,22.4,0,0,1-14.7,5.306,22.846,22.846,0,0,1-8.6-1.661Zm18.929-37.7c-10.1,0-18.321,7.123-18.321,15.878a14.669,14.669,0,0,0,4.24,10.145l.468.487L5.493,35.584l6.585-3.292.523.226A20.408,20.408,0,0,0,20.764,34.2c10.192,0,18.484-7.123,18.484-15.878S30.956,2.443,20.764,2.443Zm0,0"
          transform="translate(0)"
          fill="#4a4a4a"
        />
        <path
          id="Caminho_376"
          data-name="Caminho 376"
          d="M135,150h19.543v2.443H135Zm0,0"
          transform="translate(-124.007 -137.786)"
          fill="#4a4a4a"
        />
        <path
          id="Caminho_377"
          data-name="Caminho 377"
          d="M135,210h19.543v2.443H135Zm0,0"
          transform="translate(-124.007 -192.9)"
          fill="#4a4a4a"
        />
        <path
          id="Caminho_378"
          data-name="Caminho 378"
          d="M135,270h14.657v2.443H135Zm0,0"
          transform="translate(-124.007 -248.014)"
          fill="#4a4a4a"
        />
      </g>
    </svg>
  );

  let IconModeloMensagem = (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      viewBox="0 0 27.703 26.675"
    >
      <g id="mensagem" transform="translate(0 0)">
        <path
          id="Caminho_375"
          data-name="Caminho 375"
          d="M1.22,26.675l1.742-6.968A11.306,11.306,0,0,1,0,12.174,11.425,11.425,0,0,1,4.081,3.529,14.7,14.7,0,0,1,13.8,0a14.886,14.886,0,0,1,9.767,3.526A11.383,11.383,0,0,1,27.7,12.174a11.383,11.383,0,0,1-4.138,8.648A14.886,14.886,0,0,1,13.8,24.348a15.181,15.181,0,0,1-5.717-1.1ZM13.8,1.623c-6.713,0-12.174,4.733-12.174,10.551a9.747,9.747,0,0,0,2.818,6.741l.311.324-1.1,4.406,4.376-2.188.347.15A13.561,13.561,0,0,0,13.8,22.725c6.772,0,12.282-4.733,12.282-10.551S20.57,1.623,13.8,1.623Zm0,0"
          transform="translate(0 0)"
          fill="#4a4a4a"
        />
        <path
          id="Caminho_376"
          data-name="Caminho 376"
          d="M135,150h12.986v1.623H135Zm0,0"
          transform="translate(-127.696 -141.884)"
          fill="#4a4a4a"
        />
        <path
          id="Caminho_377"
          data-name="Caminho 377"
          d="M135,210h12.986v1.623H135Zm0,0"
          transform="translate(-127.696 -198.637)"
          fill="#4a4a4a"
        />
        <path
          id="Caminho_378"
          data-name="Caminho 378"
          d="M135,270h9.739v1.623H135Zm0,0"
          transform="translate(-127.696 -255.391)"
          fill="#4a4a4a"
        />
      </g>
    </svg>
  );

  let IconWhatsApp = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" viewBox="0 0 24 24">
      <g id="whatsapp" transform="translate(0 0)">
        <path
          id="Caminho_414"
          data-name="Caminho 414"
          d="M17.507,14.307l-.009.075c-2.2-1.1-2.429-1.242-2.713-.816-.2.3-.771.964-.944,1.162s-.349.21-.646.075a8.116,8.116,0,0,1-2.4-1.485,9.073,9.073,0,0,1-1.66-2.07c-.293-.506.32-.578.878-1.634a.55.55,0,0,0-.025-.524c-.075-.15-.672-1.62-.922-2.206s-.487-.51-.672-.51a1.488,1.488,0,0,0-1.368.344c-1.614,1.774-1.207,3.6.174,5.55,2.714,3.552,4.16,4.206,6.8,5.114a4.137,4.137,0,0,0,1.88.121A3.077,3.077,0,0,0,17.9,16.077a2.475,2.475,0,0,0,.18-1.425c-.074-.135-.27-.21-.57-.345Z"
          fill="#4a4a4a"
        />
        <path
          id="Caminho_415"
          data-name="Caminho 415"
          d="M20.52,3.449C12.831-3.984.106,1.407.1,11.893a11.836,11.836,0,0,0,1.6,5.945L0,24l6.335-1.652A11.971,11.971,0,0,0,24,11.9a11.794,11.794,0,0,0-3.495-8.411ZM22,11.866A9.956,9.956,0,0,1,6.99,20.37l-.36-.214-3.75.975,1.005-3.645-.239-.375A9.918,9.918,0,0,1,19.093,4.876,9.788,9.788,0,0,1,22,11.866Z"
          fill="#4a4a4a"
        />
      </g>
    </svg>
  );

  let IconWhatsAppNoList = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="33" viewBox="0 0 24 24">
      <g id="whatsapp" transform="translate(0 0)">
        <path
          id="Caminho_414"
          data-name="Caminho 414"
          d="M17.507,14.307l-.009.075c-2.2-1.1-2.429-1.242-2.713-.816-.2.3-.771.964-.944,1.162s-.349.21-.646.075a8.116,8.116,0,0,1-2.4-1.485,9.073,9.073,0,0,1-1.66-2.07c-.293-.506.32-.578.878-1.634a.55.55,0,0,0-.025-.524c-.075-.15-.672-1.62-.922-2.206s-.487-.51-.672-.51a1.488,1.488,0,0,0-1.368.344c-1.614,1.774-1.207,3.6.174,5.55,2.714,3.552,4.16,4.206,6.8,5.114a4.137,4.137,0,0,0,1.88.121A3.077,3.077,0,0,0,17.9,16.077a2.475,2.475,0,0,0,.18-1.425c-.074-.135-.27-.21-.57-.345Z"
          fill="#4a4a4a"
        />
        <path
          id="Caminho_415"
          data-name="Caminho 415"
          d="M20.52,3.449C12.831-3.984.106,1.407.1,11.893a11.836,11.836,0,0,0,1.6,5.945L0,24l6.335-1.652A11.971,11.971,0,0,0,24,11.9a11.794,11.794,0,0,0-3.495-8.411ZM22,11.866A9.956,9.956,0,0,1,6.99,20.37l-.36-.214-3.75.975,1.005-3.645-.239-.375A9.918,9.918,0,0,1,19.093,4.876,9.788,9.788,0,0,1,22,11.866Z"
          fill="#4a4a4a"
        />
      </g>
    </svg>
  );

  let IconPlay = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" style={{ marginLeft: 5 }} viewBox="0 0 12.7 14.819">
      <g id="layer1" transform="translate(-2.117 -281.124)">
        <path id="path828" d="M3.217,281.125a1.059,1.059,0,0,0-1.1,1.059v12.7a1.058,1.058,0,0,0,1.6.908l10.584-6.35a1.059,1.059,0,0,0,0-1.816l-10.584-6.35a1.057,1.057,0,0,0-.5-.15Z" fill="#fff"/>
      </g>
    </svg>
  );

  let IconPause = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" style={{ marginLeft: 1 }} viewBox="0 0 22 28">
      <g id="Layer_33" data-name="Layer 33" transform="translate(-5 -2)">
        <path id="Caminho_419" data-name="Caminho 419" d="M14,5V27a3,3,0,0,1-3,3H8a3,3,0,0,1-3-3V5A3,3,0,0,1,8,2h3a3,3,0,0,1,3,3ZM24,2H21a3,3,0,0,0-3,3V27a3,3,0,0,0,3,3h3a3,3,0,0,0,3-3V5A3,3,0,0,0,24,2Z" fill="#fff"/>
      </g>
    </svg>
  );

  let history = useHistory();

  const [Listas, setListas] = useState([]);
  const [ListasSelecionadas, setListasSelecionadas] = useState([]);
  const [pageSizeDataGridListas, setpageSizeDataGridListas] = useState(10);
  const [loadingDataGridListas, setloadingDataGridListas] = useState(true);

  const [showModalNovaLista, setshowModalNovaLista] = useState(false);
  const [showModalEditLista, setshowModalEditLista] = useState(false);
  const [idEditLista, setidEditLista] = useState();
  const [codeEditLista, setcodeEditLista] = useState(null);

  const intervalIdRef = useRef(null);

  const [permissoesdoUsuario, setpermissoesdoUsuario] = useState([]);

  const getListas = async (termo = "") => {
    setloadingDataGridListas(true);

    //GET DADOS USER
    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let response = await api.get(`listagemListasTransmissao?termo=${termo}`, {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${auth.token}`,
      },
    });

    if (!response.data.error_code) {
      setListas(response.data);

      const hasStatus2 = response.data.some(item => item.status === 2);

      if (hasStatus2 && !intervalIdRef.current) {
        intervalIdRef.current = setInterval(() => getListas(), 180000);
      } else if (!hasStatus2 && intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
        intervalIdRef.current = null;
      }
    }

    setloadingDataGridListas(false);
  };

  const permissoesUsuario = async () => {
    //GET DADOS USER
    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let permissoes = await api.post(
      "permissoesUsuario",
      {},
      {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${auth.token}`,
        },
      }
    );

    setpermissoesdoUsuario(permissoes.data.modelosMensagens);
  };

  useEffect(() => {
    getListas();
    permissoesUsuario();

    return () => {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDetalhesLista = async (props) => {
    let id = props.id;
    setidEditLista(id);
    setcodeEditLista(null);
    setshowModalEditLista(true);
  };

  const handleDetalhesListaCode = async (code) => {
    setcodeEditLista(code);
    setidEditLista(null);
    setshowModalEditLista(true);
  };

  const handleDeleteLista = async () => {
    Swal.fire({
      title: "Tem certeza?",
      text: "Deseja deletar a(s) Lista(s) selecionada(s)?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0554C2",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim deletar",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let auth = localStorage.getItem("auth");
        auth = JSON.parse(auth);

        let listasid = [];

        for await (let id of ListasSelecionadas) {
          let dados = { id: id };
          listasid.push(dados);
        }

        await api.delete("deleteListaTransmissao", {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${auth.token}`,
          },
          data: { listasIds: listasid },
        });

        getListas();

        Swal.fire("Deletado!", "Lista(s) deleta(s) com sucesso!", "success");
      }
    });
  };

  const handleSearchListas = async (event) => {
    let termo = event.target.value;

    if (termo.length >= 3) {
      getListas(termo);
    } else {
      getListas();
    }
  };

  const updateStatusLista = async (id, status) => {

    async function handleupdateStatusLista(id, status){
      //GET DADOS USER
      let auth = localStorage.getItem("auth");
      auth = JSON.parse(auth);

      await api.put(
        "updateStatusListaTransmissao",
        {
          id: id,
          status: status,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );

      getListas();
    }

    if(status === 2){

      Swal.fire({
        title: "Confirmação de Envio!",
        text: "Ao confirmar o Play, sua lista será enviada! Você não poderá reverter o envio dos contatos já enviados.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#0554C2",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, iniciar envio!",
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          handleupdateStatusLista(id, status)
        }
      });

    }else{
      handleupdateStatusLista(id, status)
    }    
  };

  const columnsDataGridListas = [
    {
      field: "nome",
      headerName: "Nome da lista",
      flex: 6,
      minWidth: 300,
      editable: false,
      valueFormatter: (params) => {
        return params.value;
      },
    },
    {
      field: "agendamento",
      headerName: "Data de disparo",
      flex: 1,
      minWidth: 200,
      editable: false,
      valueFormatter: (params) => {
        return moment(params.value).format('DD/MM/YYYY HH:mm');
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: 300,
      editable: false,
      renderCell: (params) => {

        switch (params.value) {
          case 1:
                  return <span className='listStatus pausado'>Pausado</span>
              break;
          case 2:
                  return <span className='listStatus emprogresso'>Em Progresso</span>
              break;
          case 3:
                return <span className='listStatus concluido'>Concluído</span>
            break;
          case 4:
              return <span className='listStatus cancelado'>Cancelado</span>
            break;
        } 
      },
    },
    {
      field: 'id',
      headerName: 'Ação',
      flex: 1,
      editable: false,
      renderCell: (params) => (
        <>
          {params.row.status === 1 ? (
            <ButtonCirclePrimary icon={<IconPlay />} onClick={() => { updateStatusLista(params.row.id, 2) }} />
          ) : params.row.status === 2 ? (
            <ButtonCirclePrimary icon={<IconPause />} onClick={() => { updateStatusLista(params.row.id, 1) }} />
          ) : null}
        </>
      ),
    },
  ];

  function nenhumaListaEncontratda() {
    return (
      <GridOverlay>
        <div className="container-no-data-grid">
          {permissoesdoUsuario[0]?.view ? (
            <>
              <IconWhatsAppNoList />
              <p>Nenhuma Lista encontrada</p>
            </>
          ) : (
            <>
              <IconNoAuth />
              <p style={{ marginTop: "-0px" }}>
                Você não possui autorização para visualizar as Listas de
                transmissões!
              </p>
            </>
          )}
        </div>
      </GridOverlay>
    );
  }

  let itensSubMenu = [
    {
      active: "",
      title: "Pessoas",
      rota: "/cadastros/pessoas",
      icon: <IconPessoa />,
    },
    {
      active: "",
      title: "Veículos",
      rota: "/cadastros/veiculos",
      icon: <IconCarro />,
    },
    {
      active: "",
      title: "Contratos",
      rota: "/cadastros/contratos",
      icon: <IconContrato />,
    },
    // {
    //   active: "",
    //   title: "Modelos de mensagens",
    //   rota: "/cadastros/modelosmensagem",
    //   icon: <IconModeloMensagem />,
    // },
    {
      active: "active",
      title: "Listas de Transmissões",
      rota: "/cadastros/listasTransmissoes",
      icon: <IconWhatsApp />,
    },
  ];

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Nexcar - Listas de transmissão</title>
        </Helmet>
      </HelmetProvider>

      <ModalNovaLista
        visible={showModalNovaLista}
        change={setshowModalNovaLista}
        getListas={getListas}
        handleDetalhesListaCode={handleDetalhesListaCode}
      />
      <ModalEditLista
        id={idEditLista}
        code={codeEditLista}
        visible={showModalEditLista}
        change={setshowModalEditLista}
        getListas={getListas}
      />

      <div>
        <div className="container-page">
          <Submenu itens={itensSubMenu} />

          <div className="container">
            <div className="box-actions-veiculos">
              {permissoesdoUsuario[2]?.insert && (
                <ButtonSquadPrimary
                  icon={<IconPlusBtn />}
                  onClick={() => {
                    setshowModalNovaLista(true);
                  }}
                />
              )}

              {ListasSelecionadas.length > 0 && (
                <>
                  {permissoesdoUsuario[1].delete && (
                    <div style={{ marginLeft: "5px" }}>
                      <ButtonSquadDanger
                        icon={<IconLixeira />}
                        onClick={() => {
                          handleDeleteLista();
                        }}
                      />
                    </div>
                  )}
                </>
              )}

              <div style={{ marginLeft: "5px", width: "100%" }}>
                <InputSearchActions
                  onChange={handleSearchListas}
                  placeholder="Procure uma Lista de transmissão"
                />
              </div>
            </div>

            <div className="container-data-grid">
              <DataGrid
                sx={{ minHeight: "400px" }}
                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                autoHeight
                rows={Listas}
                columns={columnsDataGridListas}
                pageSize={pageSizeDataGridListas}
                onPageSizeChange={(newPageSize) =>
                  setpageSizeDataGridListas(newPageSize)
                }
                rowsPerPageOptions={[10, 50, 100]}
                checkboxSelection
                disableSelectionOnClick
                editMode="row"
                loading={loadingDataGridListas}
                disableColumnMenu={true}
                onSelectionModelChange={(props) => {
                  setListasSelecionadas(props);
                }}
                // onRowClick
                onCellClick={(props) => {
                  if (props.field !== "__check__" && props.field !== "id") {
                    handleDetalhesLista(props);
                  }
                }}
                components={{
                  NoRowsOverlay: nenhumaListaEncontratda,
                }}
              />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
