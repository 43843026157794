import React, {useState, useEffect} from 'react';
import './style.css';
import { useHistory, Link, useLocation } from "react-router-dom";
import api from "../../services/api";

import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import MailIcon from '@mui/icons-material/Mail';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import LanguageIcon from '@mui/icons-material/Language';


export default function Header() {

  const location = useLocation();

  let Logo = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="158.125" height="38.131" viewBox="0 0 158.125 38.131">
      <g id="Camada_x0020_1" transform="translate(0 0.001)">
        <g id="_2768542192960" transform="translate(0 -0.001)">
          <path id="Caminho_1" data-name="Caminho 1" d="M4.317,389.97,0,414.454H6.974l2.2-12.5,12.287,12.5h3.2l4.317-24.484H22.008l-2.234,12.65L7.608,389.97H4.317Zm26.235,0-4.317,24.484H46.612l.966-5.525H34.2l.785-4.468H45.1l.966-5.555H35.956l.6-3.381H49.571l1-5.555H30.551Z" transform="translate(0 -384.278)"/>
          <path id="Caminho_2" data-name="Caminho 2" d="M5460.055,346.343l1.128-6.34a16,16,0,0,1-8.9,2.682c-5.639,0-9.235-3.109-8.565-6.919.67-3.84,5.364-6.888,11-6.888a10.972,10.972,0,0,1,7.834,2.957l1.158-6.644a13.512,13.512,0,0,0-8.5-2.53c-8.657,0-16.764,5.852-18.044,13.106s4.755,13.137,13.412,13.137A17.714,17.714,0,0,0,5460.055,346.343Zm-1.372,1.8h7.437l2.652-3.871h13.441l1.311,3.871h7.437l-8.778-24.719h-6.035Zm13.959-9.479,5.151-7.5,2.53,7.5Zm21.976-15.24-4.359,24.719h7.041l1.676-9.662,8.047,9.662h8.442l-7.986-9.3c4.3-1.036,7.5-3.78,8.169-7.529.792-4.511-2.621-7.894-8.26-7.894h-12.771Zm5.06,11.247,1.066-6.065h3.6c3.323,0,4.359,1.463,4.084,3.017-.274,1.585-2.012,3.048-4.907,3.048Z" transform="translate(-5357.639 -317.951)"/>
          <path id="Caminho_3" data-name="Caminho 3" d="M3250.79,1179.981c1.932-4.059,2.013-7.835.1-11.471l-13.267,13.121h11.52Z" transform="translate(-3190.366 -1151.455)" fill="#002f70" fillRule="evenodd"/>
          <path id="Caminho_4" data-name="Caminho 4" d="M346.711,17.435c1.916,3.637,1.857,7.393-.075,11.452l8.662-8.664c2-2,1.9-3.858,0-5.76-17.111-17.11-42.1-15.02-64.478-13.429,26.711.894,38.752,1.068,55.891,16.4Z" transform="translate(-286.576 0.001)" fill="#0554c2" fillRule="evenodd"/>
          <path id="Caminho_5" data-name="Caminho 5" d="M4580.809,334.183l11.51-11.513h-10.153l-6.076,6.076C4578.626,331.282,4580.2,332.846,4580.809,334.183Z" transform="translate(-4509.301 -317.961)" fill="#0554c2" fillRule="evenodd"/>
          <path id="Caminho_6" data-name="Caminho 6" d="M4576.079,1284.314c19.686,19.83,73.324,13.649,91.133,11-62.144,4.482-82.455-10.138-86.424-16.431C4580.176,1280.213,4578.61,1281.783,4576.079,1284.314Z" transform="translate(-4509.29 -1260.214)" fill="#0554c2" fillRule="evenodd"/>
        </g>
      </g>
    </svg>
  );

  const [activeMenu, setactiveMenu] = useState("");
  const [dadosUser, setdadosUser] = useState([]);
  let history = useHistory();

  const refreshToken = async()=>{
    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);
    
    if(!auth){
      history.push("/login");
    }

    if(auth){
      let refreshToken = await api.put('refreshToken', {}, {
        headers: {
          "Content-type": "application/json",
          "Authorization": `Bearer ${auth.refreshToken}`
        }
      });

      if(refreshToken.data.error_code === 'expirad_refresh_token'){
        localStorage.removeItem("auth");
        history.push("/login");
      }else{
        localStorage.setItem("auth",JSON.stringify(refreshToken.data));
        // window.location.href = `/resumo`;
      }
    }
  
  }

  const logout = async()=>{

    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    await api.post('logout', {}, {
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    });

    localStorage.removeItem("auth");
    history.push("/login");
    // window.location.reload();
  }

  const getdataUser = async()=>{
    //GET DADOS USER
    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    if(auth){
      let response = await api.post('detalhesUsuarioLogado', {}, 
      {
        headers: {
          "Content-type": "application/json",
          "Authorization": `Bearer ${auth.token}`
        }
      });

      setdadosUser(response.data);
    }
  }
  
  useEffect(()=>{ 
    getdataUser();
    refreshToken();

    // setInterval(function() { refreshToken(); }, 5000);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);


  useEffect(()=>{ 
    let rotaPrincipal = location.pathname.split('/');
    setactiveMenu(rotaPrincipal[1]);
  },[location]);
  

  let activeResumo, activeCadastros, activeEstoque, activeFinancas, activeFaturas = '';

  switch (activeMenu) {
    case 'resumo':
        activeResumo = 'active';
        break;
    case 'cadastros':
        activeCadastros = 'active';
        break;
    case 'cadastro':
        activeCadastros = 'active';
        break;
    case 'estoque':
        activeEstoque = 'active';
        break;
    case 'financas':
        activeFinancas = 'active';
        break;
    case 'faturas':
        activeFaturas = 'active';
      break;
    default:
        
        break;
}

  // INFO USER MENU
  const [anchorEluser, setAnchorEluser] = useState(null);
  const openuser = Boolean(anchorEluser);
  const handleClickuser = (event) => {
    setAnchorEluser(event.currentTarget);
  };
  const handleCloseuser = () => {
    setAnchorEluser(null);
  };

  // MENU
  const [anchorElmenu, setAnchorElmenu] = useState(null);
  const openmenu = Boolean(anchorElmenu);
  const handleClickmenu = (event) => {
    setAnchorElmenu(event.currentTarget);
  };
  const handleClosemenu = () => {
    setAnchorElmenu(null);
  };


  return (
    <div className='header'>
      <div className='container'>
          
          <div className='content-left'>
            <div className='box-logo'>
              <Logo/>
            </div>

            <ul className='menu'>
                <li className={activeResumo}><Link to="/resumo">Resumo</Link></li>
                <li className={activeCadastros}><Link to="/cadastros/pessoas">Cadastros</Link></li>
                <li className={activeEstoque}><Link to="/estoque">Estoque</Link></li>
                <li className={activeFinancas}><Link to="/financas/controleCaixa">Finanças</Link></li>
                <li className={activeFaturas}><Link to="/faturas">Faturas</Link></li>
            </ul>
          </div>

          <div className='box-user-info'>

            <div className='menu-mobile'>
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={openmenu ? 'long-menu' : undefined}
                aria-expanded={openmenu ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClickmenu}
              >
                <MoreVertIcon />
              </IconButton>
              
              <Menu
                anchorEl={anchorElmenu}
                id="geral-menu"
                open={openmenu}
                onClose={handleClosemenu}
                onClick={handleClosemenu}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    '&:before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              >
                  <MenuItem onClick={()=>{ handleClosemenu(); history.push("/resumo"); }}>Resumo</MenuItem>
                  <MenuItem onClick={()=>{ handleClosemenu(); history.push("/cadastros/pessoas"); }}>Cadastros</MenuItem>
                  <MenuItem onClick={()=>{ handleClosemenu(); history.push("/estoque"); }}>Estoque</MenuItem>
                  <MenuItem onClick={()=>{ handleClosemenu(); history.push("/financas/controleCaixa"); }}>Finanças</MenuItem>
                  <MenuItem onClick={()=>{ handleClosemenu(); history.push("/faturas"); }}>Faturas</MenuItem>
              </Menu>
            </div>

            <div className='box-user' onClick={handleClickuser}>
              <div className='box-avatar' >
                <Avatar src={dadosUser.foto} sx={{bgcolor: '#2B2B2B', width: 50, height: 50 }} />
              </div>

              <div className='user-info'>
                  <h3>{dadosUser.nome}</h3>
                  <p>{dadosUser.email}</p>
                  <b>{dadosUser.empresa}</b>
              </div>
            </div>
          </div>

          <Menu
              anchorEl={anchorEluser}
              id="account-menu"
              open={openuser}
              onClose={handleCloseuser}
              onClick={handleCloseuser}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              {/* <MenuItem>
                <ListItemIcon>
                  <AccountCircleIcon fontSize="small" />
                </ListItemIcon>
                Minha conta
              </MenuItem> */}

              <MenuItem onClick={()=>{ history.push(`/email/inbox`); }}>
                <ListItemIcon>
                  <MailIcon fontSize="small" />
                </ListItemIcon>
                E-mail
              </MenuItem>

              <Divider />
              <MenuItem onClick={()=>{ history.push('/empresa/config') }}>
                <ListItemIcon>
                  <CorporateFareIcon fontSize="small" />
                </ListItemIcon>
                Empresa
              </MenuItem>
              {/* <MenuItem onClick={()=>{ history.push('/empresa/site') }}>
                <ListItemIcon>
                  <LanguageIcon fontSize="small" />
                </ListItemIcon>
                Gerenciar Site
              </MenuItem> */}
              <MenuItem onClick={()=>{logout()}}>
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                Sair
              </MenuItem>
          </Menu>

      </div>
    </div>
  );
}