import React, { useState, useEffect } from 'react';
import moment from 'moment';
import api from '../../services/api';
import './style.css';

import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';

const AudioComponent = ({ data, tempo }) => {
    const [media, setMedia] = useState({
        base64: '',
        mimetype: ''
    });

    const getMediaByMessage = async (messageId) => {
        let auth = localStorage.getItem("auth");
        auth = JSON.parse(auth);

        let response = await api.post('whatsAppGetMediaByMessage', {
            "messageId": messageId
        }, {
            headers: {
                "Content-type": "application/json",
                "Authorization": `Bearer ${auth.token}`
            }
        });

        setMedia(response.data);
    };

    useEffect(() => {
        if (data.id) {
            getMediaByMessage(data.id);
        }
    }, [data]);

    return (
        <>
            { media.mimetype && (
                <div className='element-audio-msg'>
                    <audio controls>
                        <source src={`data:${media.mimetype};base64,${media.base64}`} type={media.mimetype} />
                    </audio>
                    <span>
                        <Tooltip title={`${moment(data.data).format('DD/MM/YYYY')} às ${moment(data.data).format('HH:mm')}`}>
                            <Button>{tempo}</Button>
                        </Tooltip>
                    </span>
                </div>
            )}
        </>
    );
};

export default AudioComponent;
